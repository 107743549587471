import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

export default class AdminLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: 'EMAIL',
      password: 'PASSWORD',
      showLogin: false
    }
    this.handleLogin = this.handleLogin.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('admin')) {
      this.setState({showLogin: true});
    }
  }

  handleClose() {
    this.setState({showLogin: false});
    window.location.href = '/';
  }

  handleChange(e, type) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleLogin(e) {
    e.preventDefault();
    console.log("LOGIN");
    console.log(this.state.email);
    console.log(this.state.password);

    axios.post('/api/login-test', {
      data: {
        email: this.state.email,
        password: this.state.password
      }
    })
    .then(({data}) => {
      console.log(data);
    });
    axios.post('/api/login', {
      data: {
        email: this.state.email,
        password: this.state.password
      }
    })
    .then(({data}) => {
      if (data.admin === true) {
        localStorage.setItem('token', data.token);
        window.location.href = '/admin';
      } else {
        this.emailRef.value = '';
        this.passwordRef.value = '';
        this.setState({
          email: 'INCORRECT LOGIN INFO',
          password: 'INCORRECT LOGIN INFO',
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  render() {
    return(
      <Modal show={this.state.showLogin} onHide={this.handleClose} id="loginModal">
          <Modal.Body>
            <form style={{padding:'2%'}} onSubmit={this.handleLogin}>
              <Modal.Header closeButton>
                <Modal.Title id="admin-login">Admin Login</Modal.Title>
              </Modal.Header>
              <div style={{textAlign:'center'}} className="modal-body row">
                <div className="col-sm-12">
                  <input required type="text" className="text-center" placeholder={this.state.email} style={{width:'55%'}} name="email" ref={el => this.emailRef = el} onChange={(e) => this.handleChange(e, 'login')} />
                  <input required type="password" className="text-center" placeholder={this.state.password} style={{width:'55%'}} name="password" ref={el => this.passwordRef = el} onChange={(e) => this.handleChange(e, 'login')} />
                </div>
              </div>

              <Modal.Footer>
                <button type="button" className="pointer btn-orange" onClick={this.handleClose}>CANCEL</button>
                <button type="submit" className="pointer btn-black">LOGIN</button>
              </Modal.Footer>
            </form>
          </Modal.Body>
      </Modal>
    )
  }
}
