import React from 'react';
import logo from '../../../images/logo.png'

export default class IndexPage extends React.Component {
	render() {
		return (
			<header className="masthead text-center text-white d-flex" style={{backgroundImage:`url(${logo})`,backgroundSize:"auto",backgroundPosition:"center",backgroundRepeat:"no-repeat"}}>
				<h2 className="section-heading" style={{position:"absolute", bottom:"40px"}}>Loading Your SWADisht Experience...</h2> 
			</header>
		)
	}
}

