import React from 'react';
import SearchBar from './SearchBar';
import filterIcon from '../../../images/filter.png'

const FilterBar = props => (
  <section className="filterbar text-white">
    <div className="dropdown row dropdown-filterbar">
      <div className="col-md-5 col-sm-5 col-5">
          <button className="btn btn-secondary dropdown-toggle full-width-btn" type="button" id="categoryMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontSize: '1.3em', paddingLeft: "8px", textAlign: 'left'}}>
            <p className="filterCategoryFont" style={{display: 'inherit', marginBottom: '0'}}>&#xf0b0;</p> Category
          </button>
          <div className="dropdown-menu scrollable-menu" aria-labelledby="categoryMenuButton">
            <a className="dropdown-item" onClick={props.showAll}>All</a>
            {props.categories}
        </div>
      </div>

      <div className="col-md-7 col-sm-7 col-7 sort-search">
        <SearchBar
          filterText={props.filterText}
          onFilterTextChange={props.handleFilterTextChange}
        />
      </div>

    </div>
  </section>
);

export default FilterBar;
