import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const ShareRecipes = props => (
  <section className="bg-primary">
    <div className="container text-center">
      <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <h2 className="section-heading">Recipes to share with friends <span className="subheading"> | <Link className="subheading-link" to="/recipes">See Our Recipes</Link></span></h2>
      </ScrollAnimation>
    </div>
  </section>
);

export default ShareRecipes;
