import React from 'react';

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="text-white text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <a className="white-a" href="https://www.instagram.com/swadfoodproducts/" target="_blank" rel="noopener noreferrer">
                <div className="social-circle">
                  <i className="fab fa-instagram"></i>
                </div>
              </a>
            </div>
          </div>
          <div className="footer-links row">
            <div className="col-lg-12">
	           <p>Copyright &copy; { new Date().getFullYear() } PB Brands, LLC.</p>
		   <a href="https://swad.nyc3.digitaloceanspaces.com/Privacy%20Policy%20-%20Swad%20Foods.pdf" target="_blank">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
