import React from 'react';

const ProductModal = props => (
  <div className="modal fade" id="product-modal" tabIndex="-1" role="dialog" aria-labelledby="product-modal-label" aria-hidden="true">
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <a className="close-modal" href="_#" data-dismiss="modal" aria-label="Close">x</a>
        <div className="modal-header">
          <h5 className="modal-title text-center" id="product-modal-label">Product Details</h5>
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="modal-product-images col-lg-4">
              <div className="modal-current-image">
              {
                props.product.image_path ? <img id="main-product-image" src={`${props.product.image_path}`} alt="" /> : <img id="main-product-image" src={"https://swad.nyc3.digitaloceanspaces.com/products/front/swadcomingsoon.png"} alt="" />
              }
              </div>
              <div className="modal-slides-image">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 center">
                    <ul>
                      <li>
                        {
                          props.product.image_path ? <a className="show-modal-thumbnail" onClick={() => props.changeModalImage(props.product.image_path)}><img src={`${props.product.image_path}`} alt="" /></a> : <img id="main-product-image" src={"https://swad.nyc3.digitaloceanspaces.com/products/front/swadcomingsoon.png"} alt="" />
                        }
                      </li>
                      <li>
                        {
                          props.product.nutrition ? <a className="show-modal-thumbnail" onClick={() => props.changeModalImage(props.product.nutrition)}><img src={`${props.product.nutrition}`} alt="" /></a> : null
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-desc-title">
                <div className="row">
                  <div className="col-lg-12">

                    {
                      props.product.name ? <h5 className="product-modal-title">{props.product.name} <p className="product-tag">{/*props.product.feature_tag*/}</p> </h5> : null
                    }
                  </div>
                </div>
              </div>
              <div className="product-desc semibold">
              <p>
                {
                  props.product.sku ? <span>SKU: {props.product.sku}<br/></span> : null
                }
                {
                  props.product.category ? <span>Category: {props.product.category}<br/></span> : null
                }
                {
                  props.product.product_sizes ? <span>Size: {(props.product.product_sizes.split(",")[0]).toLowerCase()}<br/> All Product Sizes: {props.product.product_sizes.toLowerCase()}</span> : null
                }
              </p>
                {
                  props.product.productofthemonth ? <p style={{fontSize: 16}}><br/><br/>{props.product.productofthemonth.description}</p> : null
                }
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row modal-recipes">
            <div className="associated-recipes col-lg-12 semibold">
            {
              props.product.recipes ?
              <p>Associated Recipes</p> : null
            }
              <div className="modal-recipes-list text-center">
              {
                props.product.recipes ? <div className="row">{
        	        props.product.recipes.map((recipe, index) => {
        	          return <div className="col-lg-3 col-sm-3"><a onClick={() => props.setRecipeModal(recipe.recipe_id)} className="secondary-a" href="_#" data-toggle="modal" data-target="#associated-recipe-modal" data-dismiss="modal"><div className="modal-recipe-item" style={{backgroundImage: "url(" + recipe.image_path + ")"}}></div><p>{recipe.name}</p></a></div>; })
        	      }</div> : null
              }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default ProductModal;
