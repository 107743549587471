import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';

import LeftNav from '../components/LeftNav.js';
import Loading from '../../Home/components/generic/Loading';
import Dashboard from '../components/Dashboard.js';
import StoreModal from '../components/StoreModal';
import ProductModal from '../components/ProductModal';
import RecipeModal from '../components/RecipeModal';
import ProductOfTheMonth from '../components/ProductOfTheMonth';
import RecipeOfTheWeek from '../components/RecipeOfTheWeek';

export default class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAdmin: false,
      allLocations: [],
      allProducts: [],
      allRecipes: [],
      locations: [],
      products: [],
      recipes: [],
      store: {},
      product: {},
      recipe: {},
      showStores: 'block',
      showProducts: 'none',
      showRecipes: 'none',
      readOnly: '',
      recipeImg: '',
      productImgFront: '',
      productImgBack: '',
      recipeOTWimg: '',
      productOTMimg: '',
      oldState: '',
      oldCity: '',
      newStore: false,
      newProduct: false,
      newRecipe: false,
      uploadingCSVStores: false,
      showPOTM: 'none',
      showROTW: 'none',
      target: '',
      checked: false,
      viewBy: 'View: All',
      sortBy: 'Sort By: Default',
      searchPlaceholder: 'Search by Store Name',
      anyRecipes: '',
      anyProducts: '',
      anySteps: '',
      mvalue: {year: new Date().getFullYear(), month: new Date().getMonth()},
      year: undefined,
      weeknum: undefined,
      pickerLang: {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        from: 'From', to: 'To',
      },
      storeShow: false,
      productShow: false,
      recipeShow: false,
      potmShow: false,
      rotwShow: false,
      selectedDaysForROTW: [],
      hoverRangeForROTW: undefined,
      productOffset: 20,
    }

    this.show = this.show.bind(this);
    this.updateStateObj = this.updateStateObj.bind(this);
    this.addNew = this.addNew.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCSVSubmit = this.handleCSVSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.getStores = this.getStores.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.getRecipes = this.getRecipes.bind(this);
    this.savedIt = this.savedIt.bind(this);
    this.handleArrChange = this.handleArrChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDel = this.handleDel.bind(this);
    this.handleAddSteps = this.handleAddSteps.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleSearchProduct = this.handleSearchProduct.bind(this);
    this.handleAddRecipe = this.handleAddRecipe.bind(this);
    this.handleSearchRecipe = this.handleSearchRecipe.bind(this);
    this.handleWeekClick = this.handleWeekClick.bind(this);
    this.handleClickMonthBox = this.handleClickMonthBox.bind(this);
    this.handleAMonthChange = this.handleAMonthChange.bind(this);
    this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.makeText = this.makeText.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDayEnter = this.handleDayEnter.bind(this);
    this.handleDayLeave = this.handleDayLeave.bind(this);
  }

  componentWillMount() {
    const token = window.localStorage.getItem('token');

    axios.post('/api/verify_token', {token})
      .then(({data}) => {
        if (data === 'OK') {
          this.setState({showAdmin: true});
          this.getStores();
          this.getProducts();
          this.getRecipes();
      } else {
          window.location.href = '/?admin=login';
        }
      });
  }

  handleClickMonthBox(e) {
    if (this.pickAMonth2) {
      this.pickAMonth2.show();
    } else {
      this.pickAMonth.show();
    }
  }

  handleAMonthChange(year, month) {
    var checkMonthSelected = this.state.allProducts.filter((product) => product.productofthemonth !== null && String(product.productofthemonth.year) === String(year) && String(product.productofthemonth.month) === String(month));
    if (checkMonthSelected.length > 0) {
      return;
    }

    this.setState({
      mvalue: {
        month: month,
        year: year
      }
    });

    if (this.state.product.productofthemonth) {
      this.setState({ product: { ...this.state.product, productofthemonth: { ...this.state.product.productofthemonth, month: month, year: year}}});
    }
  }

  handleAMonthDissmis(value) {
      this.setState({mvalue: value});
  }

  getStores() {
    console.log("get stores");
    axios.get('/api/stores?state=all')
      .then(({data}) => this.setState({locations: data, allLocations: data}))
      .catch(err => console.log("Couldn't retrieve states"));
  }

  getProducts() {
    console.log("get products");
    axios.get('/api/products')
    .then(({data}) => this.setState({products: data, allProducts: data}))
    .catch(err => console.log("Couldn't retrieve products"));
  }

  getRecipes() {
    console.log("get recipes");
    axios.get('/api/recipes')
    .then(({data}) => this.setState({recipes: data, allRecipes: data}))
    .catch(err => console.log("Couldn't retrieve recipes"));
  }

  savedIt(item, type) {
    if (type === 'product') {
      if (this.state.newProduct) {
        let allProducts = this.state.allProducts;
        allProducts.unshift(item);
        this.setState({allProducts: allProducts, products: allProducts});

      } else {
        let allProducts = this.state.allProducts.map(productObj => {
          if (productObj.barcode !== item.barcode) {
            return productObj;
          } else {
            return item;
          }
        });

        this.setState({allProducts: allProducts, products: allProducts});
      }

    } else if (type === 'recipe') {
      let allRecipes = this.state.allRecipes;
      if (this.state.newRecipe) {
        allRecipes.unshift(item);
        this.setState({allRecipes: allRecipes, recipes: allRecipes});

      } else {
        let allRecipes = this.state.allRecipes.map(recipeObj => {
          if (recipeObj.recipe_id !== item.recipe_id) {
            return recipeObj;
          } else {
            return item;
          }
        });

        this.setState({allRecipes: allRecipes, recipes: allRecipes});
      }
    } else if (type === 'store') {
      let allLocations = this.state.allLocations;
      if (this.state.newStore) {
        allLocations.unshift(item);
        this.setState({allLocations: allLocations, locations: allLocations});

      } else {
        let allLocations = this.state.allLocations.map(locationObj => {
          if (locationObj.store_id !== item.store_id) {
            return locationObj;
          } else {
            return item;
          }
        });

        this.setState({allLocations: allLocations, locations: allLocations});
      }

    } else if (type === 'store_csv') {
      let allLocations = this.state.allLocations;
      let newAllLocations = item.concat(allLocations);
      this.setState({allLocations: newAllLocations, locations: newAllLocations});
    }
  }

  show(type) {
    const allProducts = this.state.allProducts;
    const allLocations = this.state.allLocations;
    const allRecipes = this.state.allRecipes;

    this.setState({products: allProducts, recipes: allRecipes, locations: allLocations, viewBy: 'View: All', sortBy: 'Sort By: Default'});
    this.setState({showProducts: 'none'});
    this.setState({showStores: 'none'});
    this.setState({showRecipes: 'none'});
    $("#stores-tab").css({'font-weight': 'normal', 'text-decoration': 'none'});
    $("#recipes-tab").css({'font-weight': 'normal', 'text-decoration': 'none'});
    $("#products-tab").css({'font-weight': 'normal', 'text-decoration': 'none'});

    if (type === 'products') {
      $("#products-tab").css({'font-weight': 'bolder', 'text-decoration': 'underline'});
      this.getProducts();
      this.setState({showProducts: 'block', searchPlaceholder: 'Search by Product Name'});

    } else if (type === 'stores') {
      $("#stores-tab").css({'font-weight': 'bolder', 'text-decoration': 'underline'});
      this.setState({showStores: 'block', searchPlaceholder: 'Search by Store Name'});

    } else if (type === 'recipes') {
      $("#recipes-tab").css({'font-weight': 'bolder', 'text-decoration': 'underline'});
      this.getRecipes();
      this.setState({showRecipes: 'block', searchPlaceholder: 'Search by Recipe Name'});
    }
  }

  updateStateObj(item, type) {
    this.setState({readOnly: 'readOnly'});
    this.clearInput();

    if (type === 'store') {
      this.setState({store: item, newStore: false});
      this.setState({oldState: item.state});
      this.setState({oldCity: item.city});
      this.handleShow('store');

    } else if (type === 'product') {
      this.setState({product: item, newProduct: false});
      this.setState({productImgFront: item.image_path});
      this.setState({productImgBack: item.nutrition});

      if (item.recipes) {
        if (item.recipes.length > 0) this.setState({anyRecipes: 'RECIPES'});
      }
      if (item.productofthemonth) {
        this.setState({productOTMimg: item.productofthemonth.image_path})
        this.setState({mvalue: {
          year: item.productofthemonth.year,
          month: item.productofthemonth.month
        }})
      }

      this.handleShow('product');

    } else if (type === 'recipe') {
      this.setState({recipe: item, newRecipe: false});
      this.setState({recipeImg: item.image_path});

      if (item.recipeoftheweek) {
        this.setState({recipeOTWimg: item.recipeoftheweek.image_path})
        this.setState({weeknum: item.recipeoftheweek.weeknum, year: item.recipeoftheweek.year})
      }
      if (item.products) {
        if (item.products.length > 0) this.setState({anyProducts: 'PRODUCTS / INGREDIENTS'});
      }
      if (item.steps_mobile) {
        if (item.steps_mobile.length > 0) this.setState({anySteps: 'STEPS'});
      }
      this.handleShow('recipe');
    }
  }

  addNew(type) {
    this.setState({readOnly: ''});
    this.clearInput();

    if (type === 'store') {
      this.setState({store: {
        city: '',
        oldCity: '',
        oldState: '',
        name: '',
        phone_number: '',
        products: '',
        state: '',
        store_id: '',
        store_times: '',
        street: '',
        zipcode: ''
      }});

      this.setState({oldCity: '', oldState: ''});
      this.setState({newStore: true});
      this.handleShow('store');

    } else if (type === 'product') {
      this.setState({product: {
        barcode: '',
        category: '',
        image_path: '',
        nutrition: '',
        name: '',
        product_sizes: '',
        sku: ''
      }});

      this.setState({productImgFront: ''});
      this.setState({productImgBack: ''});
      this.setState({newProduct: true});
      this.setState({anyRecipes: ''});
      this.handleShow('product');

    } else if (type === 'recipe') {
      this.setState({recipe: {
        difficulty: '',
        category: '',
        duration: '',
        image_path: '',
        amount_serves: '',
        name: '',
        description: '',
        recipe_id: '',
      }});
      this.setState({recipeImg: ''});
      this.setState({newRecipe: true});
      this.setState({anyProducts: ''});
      this.setState({anySteps: ''});
      this.handleShow('recipe');
    }
  }

  handleChange(e, type) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    if (type === 'potm') {
      this.setState({ product: { ...this.state.product, productofthemonth: { ...this.state.product.productofthemonth, [name]: value}}});
    } else if (type === 'rotw') {
      this.setState({ recipe: { ...this.state.recipe, recipeoftheweek: { ...this.state.recipe.recipeoftheweek, [name]: value}}});
    } else {
      this.setState({ [type]: { ...this.state[type], [name]: value} });
    }
  }

  handleSubmit(e , type) {
    e.preventDefault();
    let oldLoc = {flag: false};

    if (type === 'store') {
      if (!this.state.newStore && (this.state.oldCity !== this.state.store.city || this.state.oldState !== this.state.store.state)) {
        oldLoc.flag = true;
        oldLoc.city = this.state.oldCity;
        oldLoc.state = this.state.oldState;
      }
      axios.post('/api/storesave', {
        data: this.state.store, oldLoc
      })
      .then(({ data }) => {
        if (data === 'OK') {
          this.savedIt(this.state.store, 'store');
          this.handleClose('store');

        } else {
          alert("Couldn't save store at this time - please try again!");
	  console.log(data);
        }
      })
      .catch(err => console.log("Couldn't save store"));

      this.show('stores');

    } else if (type === 'product') {
      const data = new FormData();
      data.append('front', this.uploadInputFront.files[0]);
      data.append('back', this.uploadInputBack.files[0]);

      if (this.uploadInputProductOTMimg2) data.append('potm', this.uploadInputProductOTMimg2.files[0]);
      data.append('product', JSON.stringify(this.state.product));
      axios.post('/api/productsave', data)
        .then(({ data }) => {
          if (data === 'OK') {
            var new_imagepath = '';
            var new_nutritionpath = '';
            if (this.state.product.image_path) {
              new_imagepath = `https://swad.nyc3.digitaloceanspaces.com/products/front/${this.state.product.barcode}.png`;
            }
            if (this.state.product.nutrition) {
              new_nutritionpath = `https://swad.nyc3.digitaloceanspaces.com/products/back/${this.state.product.barcode}.png`;
            }
            this.setState({ product: { ...this.state.product, image_path: `${new_imagepath}`, nutrition: `${new_nutritionpath}`}});
            this.savedIt(this.state.product, 'product');
            this.handleClose('product');

          } else {
            alert("Couldn't save product at this time - please try again!");
          }
        })
        .catch(err => alert("Couldn't save product"));

        this.show('products');

    } else if (type === 'recipe') {
      const data = new FormData();
      data.append('image', this.uploadInputRecipeImg.files[0]);


      if (this.uploadInputROTW2) {
        data.append('rotw', this.uploadInputROTW2.files[0]);
      }
      data.append('recipe', JSON.stringify(this.state.recipe));
      console.log(this.state.recipe);
      axios.post('/api/recipesave', data)
      .then(({ data }) => {
        if (data === 'OK') {
          this.setState({ recipe: { ...this.state.recipe, image_path: `https://swad.nyc3.digitaloceanspaces.com/recipes/${this.state.recipe.name.replace(/ /g,"%20")}`}});

          this.savedIt(this.state.recipe, 'recipe');
          this.handleClose('recipe');

        } else {
          alert("Couldn't save recipe at this time - please try again!");
          console.log(data);
        }
      })
      .catch(err => console.log(`Couldnt save recipe ${err}`));

      this.show('recipes');

    } else if (type === 'recipeOTW') {
      const data = new FormData();
      const recipeOTW = {
        recipe_id: this.state.recipe.recipe_id,
        description: this.rotwDescription.value,
        weeknum: this.state.weeknum,
        year: this.state.year,
      }

      data.append('image', this.uploadInputRecipeOTWimg.files[0]);
      data.append('recipe', JSON.stringify(recipeOTW));

      axios.post('/api/add_rotw', data)
        .then(({data}) => {
          this.getRecipes()
          this.setState({showROTW: 'none'});
          this.setState({checked: true});
          this.handleClose('rotw');
        });

    } else if (type === 'productOTM') {
      var checkMonthSelected = this.state.allProducts.filter((product) => product.productofthemonth !== null && String(product.productofthemonth.year) === String(this.state.mvalue.year) && String(product.productofthemonth.month) === String(this.state.mvalue.month));
      if (checkMonthSelected.length > 0) {
          return;
      }

      const data = new FormData();
      const productOTM = {
        sku: this.state.product.sku,
        barcode: this.state.product.barcode,
        description: this.potmDescription.value,
        dates: this.state.mvalue
      }
      data.append('image', this.uploadInputProductOTMimg.files[0]);
      data.append('product', JSON.stringify(productOTM));

      axios.post('/api/add_potm', data)
        .then(({data}) => {
          if (data === 'OK') {
            this.getProducts();
            this.setState({showPOTM: 'none'});
            this.setState({checked: true});
            this.handleClose('potm');
          }
        });
    }
  }

  nextPage(type) {
    if (type === 'product') {
      this.setState({productOffset: this.state.productOffset+20});
    }
  }

  handleDelete(obj, type) {
    if (type === 'store') {
      axios.post('/api/storedelete', {
        data: obj
      }).then(() => this.getStores());
    } else if (type === 'product') {
      axios.post('/api/productdelete', {
        data: obj
      }).then(() => this.getProducts());
    } else if (type === 'recipe') {
      axios.post('/api/recipedelete', {
        data: obj
      }).then(() => this.getRecipes());
    }
  }

  handleCSVSubmit(e) {
    let stores = e;
    this.setState({uploadingCSVStores: true});
    var newStores = [];
    $.each(stores, function(index, store) {
      let storeData = {'store_id': store[0], 'name': store[1], 'street': store[2], 'city': store[3], 'state': store[4], 'zipcode': store[5], 'phone_number': store[6], 'store_times': store[7]};
      newStores.push(storeData);

      let oldLoc = {flag: false};
        axios.post('/api/storesave', {
          data: storeData, oldLoc
        })
        .then(({ data }) => {
          if (data === 'OK') {
            // newStores.push(storeData);
            // this.savedIt(storeData, 'store');

          } else {
            console.log("Couldn't save store at this time - please try again!");
          }
        })
        .catch(err => console.log("Couldn't save store"));

        if (index == stores.length-1) {
          this.handleClose('store');
          this.setState({uploadingCSVStores: false});
          this.savedIt(newStores, 'store_csv');
          this.show('stores');
        }
    }.bind(this));
  }

  handleImageChange(e, type) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      if (type === 'front') {
        this.setState({productImgFront: reader.result});
      } else if (type === 'back') {
        this.setState({productImgBack: reader.result});
      } else if (type === 'recipeOTW') {
        this.setState({recipeOTWimg: reader.result});
      } else if (type === 'productOTM') {
        this.setState({productOTMimg: reader.result});
      } else if (type === 'recipe') {
        this.setState({recipeImg: reader.result});
      }
    }
    reader.readAsDataURL(file);
  }

  handleArrChange(e, idx, type) {
    if (type === 'steps') {
      const newSteps = this.state.recipe.steps_mobile;
      newSteps[idx] = e.target.value;
    } else if (type === 'ingredients') {
      const ingredientsArr = this.state.recipe.ingredients;
      ingredientsArr[idx] = e.target.value;
    }
  }

  handleRecipeProductsChange(event, product, idx) {
    const name = event.target.name;
    const value = event.target.value;
    product[name] = value;
  }

  handleCheck(e, item, type) {
    this.clearInput();
    this.setState({weeknum: undefined, year: undefined});

    this.setState({mvalue: {
      year: new Date().getFullYear(),
      month: new Date().getMonth()
    }});

    if (e.target === this.state.target) this.state.target.checked = true; this.setState({checked: false});
    if ((e.target === this.state.target) && (this.state.checked)) this.state.target.checked = false; this.setState({checked: false});
    if (type === 'potm') {
      // this.potmDescription.value = '';

      const flag = e.target.checked;
      this.setState({target: e.target});

      if (flag) {
        this.setState({showPOTM:'block'});
        this.handleShow('potm');
        this.setState({productOTMimg: ''});
        this.setState({product: item});
      } else {
        const data = {};
        data.barcode = item.barcode;
        data.sku = item.sku;

        axios.post('/api/del_potm', data)
          .then(() => {
            this.getProducts();
            this.setState({checked: false});
          })
      }
    } else if (type === 'rotw') {
      // this.rotwDescription.value = '';

      const flag = e.target.checked;
      this.setState({target: e.target});

      if (flag) {
        this.setState({showROTW:'block'})
        this.handleShow('rotw');
        this.setState({recipeOTWimg: ''});
        this.setState({recipe: item});
      } else {
        const data = {};
        data.recipe_id = item.recipe_id;

        axios.post('/api/del_rotw', data)
          .then(() => {
            this.getRecipes();
            this.setState({checked: false});
          });
      }
    }
  }

  filterViewAll = (input) => {
    if (this.state.showProducts === 'block') {
      const allProducts = this.state.allProducts;
      input.value = '';
      this.setState({ products: allProducts, viewBy: 'View: All' });

    } else if (this.state.showRecipes === 'block') {
      const allRecipes = this.state.allRecipes;
      input.value = '';
      this.setState({ recipes: allRecipes, viewBy: 'View: All' });

    } else if (this.state.showStores === 'block') {
      const allLocations = this.state.allLocations;
      input.value = '';
      this.setState({ locations: allLocations, viewBy: 'View: All' });
    }
  }

  filterFeatured = (input) => {
    if (this.state.showProducts === 'block') {
      const allProducts = this.state.allProducts;
      const filterSearch = allProducts.filter(productObj => {
        if (productObj.productofthemonth) {
          return productObj;
        }
      });

      this.setState({ products: allProducts });
      this.setState({ products: filterSearch, viewBy: 'View: Featured' });

    } else if (this.state.showRecipes === 'block') {
      const allRecipes = this.state.allRecipes;
      const filterSearch = allRecipes.filter(recipeObj => {
        if (recipeObj.recipeoftheweek) {
          return recipeObj;
        }
      });

      this.setState({ recipes: allRecipes });
      this.setState({ recipes: filterSearch, viewBy: 'View: Featured' });
    }
  }

  filterItems = (event) => {
    if (this.state.showProducts === 'block') {
      const search = event.target.value.toLowerCase();
      const allProducts = this.state.allProducts;
      const filterSearch = allProducts.filter(productObj => {
        if (productObj.name.toLowerCase().includes(search)) {
          return productObj;
        }
      });

      this.setState({ products: filterSearch });
      if (search === '') this.setState({ products: allProducts });

    } else if (this.state.showRecipes === 'block') {
      const search = event.target.value.toLowerCase();
      const allRecipes = this.state.allRecipes;
      const filterSearch = allRecipes.filter(recipeObj => {
        if (recipeObj.name.toLowerCase().includes(search)) {
          return recipeObj;
        }
      });

      this.setState({ recipes: filterSearch });
      if (search === '') this.setState({ recipes: allRecipes });

    } else if (this.state.showStores === 'block') {
      const search = event.target.value.toLowerCase();
      const allLocations = this.state.allLocations;
      const filterSearch = allLocations.filter(locationObj => {
        if (locationObj.name.toLowerCase().includes(search)) {
          return locationObj;
        }
      });

      this.setState({ locations: filterSearch });
      if (search === '') this.setState({ locations: allLocations });
    }
  }

  sortRowsBy = (input) => {
    if (input === 'Default') {
      if (this.state.showProducts === 'block') {
        const allProducts = this.state.allProducts;
        this.setState({ products: allProducts, sortBy: `Sort By: Default` });
        // input.value = '';

      } else if (this.state.showRecipes === 'block') {
        const allRecipes = this.state.allRecipes;
        this.setState({ recipes: allRecipes, sortBy: `Sort By: Default` });
        // input.value = '';

      } else if (this.state.showStores === 'block') {
        const allLocations = this.state.allLocations;
        this.setState({ locations: allLocations, sortBy: `Sort By: Default` });
        // input.value = '';
      }
    }

    if (this.state.showProducts === 'block') {
      const allProducts = this.state.allProducts;
      const filterSearch = allProducts.sort(function(a, b){
        if (input === 'Featured') {
          let x = a.feature_tag;
          let y = b.feature_tag;
          if (y == null && x) {return -1;}
          if (x == null && y) {return 1;}
          if (x < y) {return 1;}
          if (x > y) {return -1;}
          return 0;

        } else if (input === 'Sizes') {
          let x = a.product_sizes;
          let y = b.product_sizes;
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;

        } else if (input === 'Category') {
          let x = a.category;
          let y = b.category;
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;

        } else {
          let x = a.sku;
          let y = b.sku;
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;
        }
      });

      this.setState({products: allProducts});
      this.setState({ products: filterSearch, sortBy: `Sort By: ${input}` });

    } else if (this.state.showRecipes === 'block') {
      const allRecipes = this.state.allRecipes;
      const filterSearch = allRecipes.sort(function(a, b){
        if (input === 'Featured') {
          let x = a.featured;
          let y = b.featured;
          if (y == null && x) {return -1;}
          if (x == null && y) {return 1;}
          if (x < y) {return 1;}
          if (x > y) {return -1;}
          return 0;

        } else {
          let x = a.category;
          let y = b.category;
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;
        }
      });

      this.setState({ recipes: allRecipes });
      this.setState({ recipes: filterSearch, sortBy: `Sort By: ${input}` });

    }  else if (this.state.showStores === 'block') {
      const allLocations = this.state.allLocations;
      const filterSearch = allLocations.sort(function(a, b){
        if (input === 'Name') {
          let x = a.name;
          let y = b.name;
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;

        } else {
          let x = a.state;
          let y = b.state;
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;
        }
      });

      this.setState({ locations: allLocations });
      this.setState({ locations: filterSearch, sortBy: `Sort By: ${input}` });

    }
  }

  closeModal(type) {
    if (!this.state.target) return
    if (this.state.showPOTM === 'block' || this.state.showROTW === 'block') {
      this.setState({showPOTM: 'none'});
      this.setState({showROTW: 'none'});
      this.handleClose('potm');
      this.handleClose('rotw');
      if (this.state.target.checked && (!this.state.checked)) this.state.target.checked = false;
    }
  }

  handleDel(e, item, idx, arr) {
    let items = arr;
    items.splice(idx, 1);
    this.setState({recipes: this.state.recipes});
  }

  handleAddSteps() {
    if (!this.state.recipe.steps_mobile) {
      this.setState({recipe: { ...this.state.recipe, steps_mobile: [''] } });
      this.setState({anySteps: 'STEPS'})

    } else {
      const arr = this.state.recipe.steps_mobile;

      arr.push('');
      this.setState({recipe: this.state.recipe});
    }
  }

  handleAddRecipe() {
    if (!this.state.product.recipes) {
      this.setState({product: { ...this.state.product, recipes: [''] } });
      this.setState({anyRecipes: 'RECIPES'});

    } else {
      const arr = this.state.product.recipes;
      arr.push('');
      this.setState({product: this.state.product});
    }
  }

  handleAddProduct() {
    if (!this.state.recipe.products) {
      this.setState({recipe: { ...this.state.recipe, products: [{name: '', sku: '', image_path: '/img/logo.png', barcode: ''}]}});
      this.setState({anyProducts: 'PRODUCTS / INGREDIENTS'});

    } else {
      this.setState({ recipe: {...this.state.recipe, products: this.state.recipe.products.concat([{name: '', sku: '', image_path: '/img/logo.png', barcode: ''}]) }});
    }
  }

  handleSearchProduct(selectedOption, idx) {
    if (selectedOption.barcode) {
      const {name, sku, image_path, barcode} = selectedOption;
      const selectedObj = {
        name: name,
        sku: sku,
        image_path: image_path,
        barcode: barcode
      };
      const arr = this.state.recipe.products;
      arr[idx] = selectedObj;
      this.setState({recipe: this.state.recipe});

    } else {
      if (!this.state.recipe.ingredients) {
        this.setState({ recipe: { ...this.state.recipe, ingredients: [selectedOption.value]} });
        this.state.recipe.products.pop(); //Remove the empty product we added to the recipe.products array to create the new field,
      } else {
        const ingredients = this.state.recipe.ingredients.concat([selectedOption.value]);
        this.setState({ recipe: {...this.state.recipe, ingredients: ingredients }});
        this.state.recipe.products.pop();
      }
    }
  }

  handleSearchRecipe(selectedOption, idx) {
    const { difficulty, steps_mobile, duration, name, ingredients, image_path, recipe_id } = selectedOption;
    const selectedObj = {
      difficulty,
      steps: steps_mobile,
      duration,
      name,
      ingredients,
      image_path,
      recipe_id,
      added_on: Date.now()
    };

    const arr = this.state.product.recipes;
    arr[idx] = selectedObj;
    this.setState({product: this.state.product});
  }

  handleDayEnter(date) {
    let weekRange = {
      from: moment(date)
        .startOf('week')
        .toDate(),
      to: moment(date)
        .endOf('week')
        .toDate(),
    }
    this.setState({
      hoverRangeForROTW: weekRange,
    });
  }

  handleDayChange(date) {
    let weekRange = {
      from: moment(date)
        .startOf('week')
        .toDate(),
      to: moment(date)
        .endOf('week')
        .toDate(),
    }

    const days = [weekRange.from];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekRange.from)
          .add(i, 'days')
          .toDate()
      );
    }

    this.setState({
      selectedDaysForROTW: days,
    });
  }

  handleDayLeave() {
    this.setState({
      hoverRangeForROTW: undefined
    })
  }

  handleWeekClick(week, days) {
    const selectedDate = new Date(days[0]);
    const year = selectedDate.getFullYear();

    var checkWeekSelected = this.state.allRecipes.filter((recipe) => recipe.recipeoftheweek !== null && String(recipe.recipeoftheweek.year) === String(year) && String(recipe.recipeoftheweek.weeknum) === String(week));
    if (checkWeekSelected.length > 0) {
      return;
    }

    this.setState({weeknum: week, year: year, selectedDaysForROTW: days});
    this.setState({ recipe: { ...this.state.recipe, recipeoftheweek: { ...this.state.recipe.recipeoftheweek, weeknum: week, year: year}}});
  }

  clearInput() {
    if (this.uploadInputFront) this.uploadInputFront.value = null
    if (this.uploadInputBack) this.uploadInputBack.value = null
    if (this.uploadInputRecipeImg) this.uploadInputRecipeImg.value = null;
    if (this.uploadInputRecipeOTWimg) this.uploadInputRecipeOTWimg.value = null;
    if (this.uploadInputProductOTMimg) this.uploadInputProductOTMimg.value = null;
    if (this.uploadInputROTW2) this.uploadInputROTW2.value = null;
    if (this.uploadInputProductOTMimg2) this.uploadInputProductOTMimg2.value = null;
  }

  makeText(m) {
    if (m && m.year && m.month) return (this.state.pickerLang.months[m.month-1] + '. ' + m.year)
    return '?'
  }

  handleChangeState(selectedOption) {
    this.setState({store: {...this.state.store, state:selectedOption.value}});
  }

  handleClose(type) {
    if (type === 'store')  {
      this.setState({ storeShow: false });
    } else if (type === 'product') {
      this.setState({ productShow: false });
    } else if (type === 'recipe') {
      this.setState({ recipeShow: false });
    } else if (type === 'potm') {
      this.setState({ potmShow: false })
    } else if (type === 'rotw') {
      this.setState({ rotwShow: false })
    }
  }

  handleShow(type) {
    if (type === 'store')  {
      this.setState({ storeShow: true });
    } else if (type === 'product') {
      this.setState({ productShow: true });
    } else if (type === 'recipe') {
      this.setState({ recipeShow: true });
    } else if (type === 'potm') {
      this.setState({ potmShow: true });
    } else if (type === 'rotw') {
      this.setState({ rotwShow: true });
    }
  }

  render() {
    if (this.state.showAdmin == false) {
      return (<Loading />);
    }

    return(
      <div>
        <LeftNav
          addNew={this.addNew}
        />

        <Dashboard
          locations={this.state.locations}
          products={this.state.products}
          recipes={this.state.recipes}
          updateStateObj={this.updateStateObj}
          handleDelete={this.handleDelete}
          handleCheck={this.handleCheck}
          viewBy={this.state.viewBy}
          searchbox={this.searchbox}
          filterItems={this.filterItems}
          filterViewAll={this.filterViewAll}
          filterFeatured={this.filterFeatured}
          sortBy={this.state.sortBy}
          sortRowsBy={this.sortRowsBy}
          searchPlaceholder={this.state.searchPlaceholder}
          show={this.show}
          showStores={this.state.showStores}
          showProducts={this.state.showProducts}
          showRecipes={this.state.showRecipes}
          nextPage={this.nextPage}
          productOffset={this.state.productOffset}
        />

        <StoreModal
          readOnly={this.state.readOnly}
          store={this.state.store}
          uploadingCSVStores={this.state.uploadingCSVStores}
          newStore={this.state.newStore}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleCSVSubmit={this.handleCSVSubmit}
          handleChangeState={this.handleChangeState}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
          storeShow={this.state.storeShow}
        />

        <ProductModal
          readOnly={this.state.readOnly}
          product={this.state.product}
          productImgFront={this.state.productImgFront}
          productImgBack={this.state.productImgBack}
          handleChange={this.handleChange}
          handleImageChange={this.handleImageChange}
          handleSubmit={this.handleSubmit}
          handleAddRecipe={this.handleAddRecipe}
          anyRecipes={this.state.anyRecipes}
          productOTMimg={this.state.productOTMimg}
          handleAMonthChange={this.handleAMonthChange}
          handleAMonthDissmis={this.handleAMonthDissmis}
          handleClickMonthBox={this.handleClickMonthBox}
          mvalue={this.state.mvalue}
          pickerLang={this.state.pickerLang}
          makeText={this.makeText}
          allRecipes={this.state.allRecipes}
          handleDel={this.handleDel}
          handleSearchRecipe={this.handleSearchRecipe}
          inputRef={el => this.uploadInputFront = el}
          inputRef2={el => this.uploadInputBack = el}
          inputRef3={el => this.uploadInputProductOTMimg2 = el}
          inputRef4={el => this.pickAMonth2 = el}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
          productShow={this.state.productShow}
        />

        <RecipeModal
          readOnly={this.state.readOnly}
          recipe={this.state.recipe}
          recipeImg={this.state.recipeImg}
          handleImageChange={this.handleImageChange}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleAddProduct={this.handleAddProduct}
          handleAddSteps={this.handleAddSteps}
          anyProducts={this.state.anyProducts}
          anySteps={this.state.anySteps}
          allProducts={this.state.allProducts}
          handleSearchProduct={this.handleSearchProduct}
          handleDel={this.handleDel}
          handleArrChange={this.handleArrChange}
          recipeOTWimg={this.state.recipeOTWimg}
          handleWeekClick={this.handleWeekClick}
          weeknum={this.state.weeknum}
          year={this.state.year}
          inputRef={el => this.uploadInputRecipeImg = el}
          inputRef2={el => this.uploadInputROTW2 = el}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
          recipeShow={this.state.recipeShow}
        />

        <ProductOfTheMonth
          showPOTM={this.state.showPOTM}
          productOTMimg={this.state.productOTMimg}
          handleAMonthChange={this.handleAMonthChange}
          handleAMonthDissmis={this.handleAMonthDissmis}
          handleImageChange={this.handleImageChange}
          handleClickMonthBox={this.handleClickMonthBox}
          handleSubmit={this.handleSubmit}
          closeModal={this.closeModal}
          mvalue={this.state.mvalue}
          pickerLang={this.state.pickerLang}
          makeText={this.makeText}
          inputRef={el => this.uploadInputProductOTMimg = el}
          inputRef2={el => this.potmDescription = el}
          inputRef3={el => this.pickAMonth = el}
          potmShow={this.state.potmShow}
        />

        <RecipeOfTheWeek
          showROTW={this.state.showROTW}
          recipeOTWimg={this.state.recipeOTWimg}
          inputRef={el => this.uploadInputRecipeOTWimg = el}
          inputRef2={el => this.rotwDescription = el}
          handleImageChange={this. handleImageChange}
          handleDayLeave={this.handleDayLeave}
          handleDayEnter={this.handleDayEnter}
          handleDayChange={this.handleDayChange}
          handleWeekClick={this.handleWeekClick}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
          rotwShow={this.state.rotwShow}
          selectedDays={this.state.selectedDaysForROTW}
          hoverRange={this.state.hoverRangeForROTW}
        />

      </div>
    )
  }
}
