import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const FeaturedProducts = props => (
  <section className="bg-primary" id="featured">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto text-center">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2 className="section-heading">Explore Our Products <span className="subheading"> |
            <button className="btn dropdown-toggle" type="button" id="categoryMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{backgroundColor: 'transparent', fontSize: '20px', color: "#f07e22", paddingLeft: "8px", textAlign: 'left'}}>
              <p className="filterCategoryFont" style={{display: 'inherit', fontFamily: "inherit", marginBottom: '0'}}>CATEGORIES</p>
            </button>
            <div className="dropdown-menu scrollable-menu" style={{width: 'inherit'}} aria-labelledby="categoryMenuButton">
              <Link className="dropdown-item" to="/products">All</Link>
              {props.categories}
            </div>
            </span></h2>
          </ScrollAnimation>
        </div>
      </div>
    </div>
    <div id="featured-products" className="container">
      {/* TINY SLIDER SET UP
      <div className="products my-slider">
        <div><div className="row">{this.state.productsleft}</div></div>
        <div><div className="row">{this.state.productsright}</div></div>
        <div><div className="row">{this.state.productsslide1}</div></div>
        <div><div className="row">{this.state.productsslide2}</div></div>
      </div>

      */}

      <div className="products row">
        <div className="arrow-holder">
          <div className="prev">
            <a href="_blank" className="prev-slide">
              <i className="fa fa-chevron-left"></i>
            </a>
          </div>
        </div>
        <div className="products-holder ">
          <div className="row">
            <div className="product-holder product-holder-left col-lg-6 col-sm-12">
              <div className="row">
                {props.featuredproduct}
              </div>
            </div>
            <div className="product-holder product-holder-right col-lg-6 col-sm-12">
              <div className="row">
                {props.fourproducts}
              </div>
            </div>
          </div>
        </div>
        <div className="arrow-holder">
          <div className="next">
            <a href="_blank" className="next-slide">
              <i className="fa fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FeaturedProducts;
