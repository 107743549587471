import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import IndexPage from './Home/containers/IndexPage';
import ProductsPage from './Home/containers/ProductsPage';
import LocationsPage from './Home/containers/LocationsPage';
import RecipesPage from './Home/containers/RecipesPage';
import NotFoundPage from './Home/containers/NotFoundPage';
import Admin from './Admin/containers/Admin';

class App extends Component {
  render() {
    const App = () => (
      <div>
        <Switch>
          <Route exact path='/' component={IndexPage} />
          <Route exact path='/products' component={ProductsPage} />
          <Route exact path='/products/:category' component={ProductsPage} />
          <Route path="/locations" component={LocationsPage} />
          <Route exact path="/recipes" component={RecipesPage} />
          <Route exact path="/recipes/:category" component={RecipesPage} />
          <Route path="/admin" component={Admin} />
          <Route path="/*" component={NotFoundPage} />
        </Switch>
      </div>
    )
    return (
      <Switch>
        <App />
      </Switch>
    );
  }
}

export default App;
