import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import Header from '../components/generic/Header';
import Loading from '../components/generic/Loading';
import RecipeOfWeek from '../components/recipes/RecipeOfWeek';
import MiniExplore from '../components/generic/MiniExplore';
import FilterBar from '../components/filter/FilterBar';
import VisiblePreviews from '../components/generic/VisiblePreviews';
import RecipePreview from '../components/recipes/RecipePreview';
import RecipeModal from '../components/modals/RecipeModal';
import RecipeProductModal from '../components/modals/RecipeProductModal';
import Footer from '../components/generic/Footer';
import AdminLogin from '../../Admin/containers/AdminLogin';

class RecipesPage extends Component {
  constructor() {
    super();
    this.state = {
      swadrecipes: "",
      swadproducts: "",
      swadcategories: "",
      isFiltered: 0,
      swadfilteredrecipes: [],
      sortByText: 'All',
      swadrecipepreviews: [],
      visiblepreviews: [],
      perPage: 10,
      isLoading: false,
      loadAmount: 4,
      filterText: "",
      totalRecipes: "Total 0 Results",
      viewrecipe: "",
      viewproduct: "",
      swadrecipeofweek: ""
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.filterTextBySearchBar = this.filterTextBySearchBar.bind(this);
    this.setRecipeModal = this.setRecipeModal.bind(this);
    this.setProductModal = this.setProductModal.bind(this);
    this.changeModalImage = this.changeModalImage.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.sortByCategory = this.sortByCategory.bind(this);
    this.showAllRecipes = this.showAllRecipes.bind(this);
  }

  createRecipesGrid(recipePreviewList) {
    var recipesGrid = [];

    var tempRegular = recipePreviewList.slice();

    for (var i = 0; i < recipePreviewList.length; i += 7) {

      var largeRecipe = null;

      if (tempRegular[0].props.featured === null) {
        var checkFeatured = tempRegular.filter((recipeView) => recipeView.props.featured != null);
        if (checkFeatured.length > 0) {
          largeRecipe = checkFeatured[0];
          var removeIndex = tempRegular.map(function (item) { return item.props.recipe_id; }).indexOf(largeRecipe.props.recipe_id);
          tempRegular.splice(removeIndex, 1);
        }
      }

      if (!largeRecipe) {
        largeRecipe = tempRegular.shift();
      }

      let largeRecipeObj = { ...largeRecipe, props: { ...largeRecipe.props, colSize: "12", productSize: "product-lg" } }
      var tmpSplicedForLarge = tempRegular.splice(0,2);
      tmpSplicedForLarge.unshift(largeRecipeObj);
      largeRecipe = tmpSplicedForLarge;

      if (i % 2 === 0) {
        recipesGrid.push(largeRecipe, tempRegular.splice(0, 4));

      } else {
        if (tempRegular.length === 1) {
          tempRegular.unshift((<div className='col-lg-6 col-sm-6'></div>));
          recipesGrid.push(tempRegular.splice(0, 4), largeRecipe);

        } else if (tempRegular.length === 3) {
          tempRegular.splice(2, 0, (<div className='col-lg-6 col-sm-6'></div>));
          recipesGrid.push(tempRegular.splice(0, 4), largeRecipe);

        } else {
          recipesGrid.push(tempRegular.splice(0, 4), largeRecipe);
        }

      }
    }

    return recipesGrid;
  }

  componentDidMount() {
    var filterCategories = []; //to filter by category name
    var categories = [];

    axios.get('/api/all_recipes')
      .then((results) => {
        var recipes = results.data;

        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
        const thisWeekNum =  Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);

        var thisYearsRecipesOfTheWeek = recipes.filter((recipe) => recipe.recipeoftheweek !== null && String(recipe.recipeoftheweek.year) === String(today.getFullYear()));
        var useRecipeOfTheWeek;
        if (thisYearsRecipesOfTheWeek.length > 0) {
          const checkWeekNumIsThere = thisYearsRecipesOfTheWeek.filter((recipe) => String(recipe.recipeoftheweek.weeknum) == String(thisWeekNum));
          if (checkWeekNumIsThere.length > 0) {
            useRecipeOfTheWeek = checkWeekNumIsThere.map((r) => {
              return <RecipeOfWeek key={r.recipeoftheweek.weeknum} rotw_desc={r.recipeoftheweek.description} bg_image_path={r.recipeoftheweek.image_path ? r.recipeoftheweek.image_path : r.image_path} setRecipeModal={this.setRecipeModal} home={true} {...r} />;
            })[0];

          } else {
            const checkPrevWeeksThisYear = thisYearsRecipesOfTheWeek.filter((recipe) => recipe.recipeoftheweek.weeknum > thisWeekNum).sort((a, b) => b.recipeoftheweek.weeknum - a.recipeoftheweek.weeknum);
            if (checkPrevWeeksThisYear.length > 0) {
              useRecipeOfTheWeek = checkPrevWeeksThisYear.map((r) => {
                return <RecipeOfWeek key={r.recipeoftheweek.weeknum} rotw_desc={r.recipeoftheweek.description} bg_image_path={r.recipeoftheweek.image_path ? r.recipeoftheweek.image_path : r.image_path} setRecipeModal={this.setRecipeModal} home={true} {...r} />;
              })[0];
            }
          }
        } else {
          useRecipeOfTheWeek = recipes.filter((recipe) => recipe.recipeoftheweek !== null).sort((a, b) => b.recipeoftheweek.weeknum - a.recipeoftheweek.weeknum).map((r) => {
            return <RecipeOfWeek key={r.recipeoftheweek.weeknum} rotw_desc={r.recipeoftheweek.description} bg_image_path={r.recipeoftheweek.image_path ? r.recipeoftheweek.image_path : r.image_path} setRecipeModal={this.setRecipeModal} home={true} {...r} />;
          })[0];
        }

        if (!useRecipeOfTheWeek) {
          useRecipeOfTheWeek = (<RecipeOfWeek key={0} bg_image_path={recipes[0].image_path} rotw_desc={"Check out one of our favorite recipes!"} setRecipeModal={this.setRecipeModal} home={true} {...recipes[0]} />);
        }

        var rotwItem;
        var sortedRecipes = recipes.filter((recipe) => {
          if (recipe.recipe_id == useRecipeOfTheWeek.props.recipe_id) {
            rotwItem = recipe;
            return false;
          }

          return true;
        });
        if (rotwItem != null) {
          sortedRecipes.unshift(rotwItem);
        }

        var regular = sortedRecipes.map((recipe) => {
          if (recipe.category != null) {
            if (!filterCategories.includes(recipe.category)) {
              filterCategories.push(recipe.category);
              categories.push(<a className="dropdown-item" onClick={(e) => this.sortByCategory(e)}>{recipe.category}</a>);
            }
          }

          return (
            <RecipePreview showFeatured={recipe.recipe_id == useRecipeOfTheWeek.props.recipe_id} key={recipe.recipe_id} setRecipeModal={this.setRecipeModal} recipeSize={'product-sm'} colSize={6} {...recipe} />
          )
        });

        const recipesGrid = this.createRecipesGrid(regular);

        window.addEventListener('scroll', this.handleScroll);
        this.setState({ swadrecipepreviews: recipesGrid, swadrecipes: recipes, swadcategories: categories, totalRecipes: `Total ${recipes.length} Results`, swadrecipeofweek: useRecipeOfTheWeek });
        var firstPagePreview = recipesGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small recipes [1, [1, 2, 3, 4]] alternating
        this.setState({ visiblepreviews: firstPagePreview });

        if (this.props.match.params.category) {
            this.sortByCategory();
        }

      });

    axios.get('/api/all_products')
      .then((results) => {
	const getProducts = results.data;
        const products = getProducts.filter((p) => {
          if (p.category != null) {
            if (p.category.toLowerCase().includes("organic")) {
              return false
            }
          }

          if (p.name.toLowerCase().includes("organic")) {
            return false
          }

          return true
        });

        this.setState({ swadproducts: products });
      });
  }

  changeModalImage(image) {
    $('#main-product-image').attr('src', image);
  }

  handleFilterTextChange(filterText) {
    this.setState({
      filterText: filterText
    });
    this.filterTextBySearchBar(filterText)
  }


  filterTextBySearchBar(filterText) {
    if (filterText === "") {
      this.showAllRecipes();
      return;
    }

    this.setState({ isFiltered: 1 });

    var filteredRecipes = []
    this.state.swadrecipes.forEach(function (recipe) {
      if (recipe.name.toLowerCase().indexOf(filterText.toLowerCase()) === -1) {
        return;
      }

      filteredRecipes.push(recipe);
    });

    var rotwItem;
    var sortedRecipes = filteredRecipes.filter((recipe) => {
      if (recipe.recipe_id == this.state.swadrecipeofweek.props.recipe_id) {
        rotwItem = recipe;
        return false;
      }

      return true;
    });
    if (rotwItem != null) {
      sortedRecipes.unshift(rotwItem);
    }
    var allRecipes = sortedRecipes.map((recipe) => {
      return (
        <RecipePreview showFeatured={recipe.recipe_id == this.state.swadrecipeofweek.props.recipe_id} key={recipe.recipe_id} setRecipeModal={this.setRecipeModal} recipeSize={'product-sm'} colSize={6} {...recipe} />
      )
    });

    const recipesGrid = this.createRecipesGrid(allRecipes);

    this.setState({ swadrecipepreviews: recipesGrid, swadfilteredrecipes: filteredRecipes, totalRecipes: `Total ${filteredRecipes.length} Results` });
    var firstPagePreview = recipesGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small products [1, [1, 2, 3, 4]] alternating
    this.setState({ visiblepreviews: firstPagePreview });
  }

  sortByCategory(e) {
    this.setState({ isFiltered: 1 });


    var category;
    if (e == null) {
        category = decodeURIComponent(this.props.match.params.category).toLowerCase();
    } else {
      category = $(e.target).text().toLowerCase();
    }
    var filteredRecipes = this.state.swadrecipes.filter(recipe => {
      if (recipe.category == null) {
        return false;
      }
      return recipe.category.toLowerCase() === category
    });

    var rotwItem;
    var sortedRecipes = filteredRecipes.filter((recipe) => {
      if (recipe.recipe_id == this.state.swadrecipeofweek.props.recipe_id) {
        rotwItem = recipe;
        return false;
      }

      return true;
    });
    if (rotwItem != null) {
      sortedRecipes.unshift(rotwItem);
    }
    var regular = sortedRecipes.map((recipe) => {
      return (
        <RecipePreview showFeatured={recipe.recipe_id == this.state.swadrecipeofweek.props.recipe_id} key={recipe.recipe_id} setRecipeModal={this.setRecipeModal} recipeSize={'product-sm'} colSize={6} {...recipe} />
      )
    });

    const recipesGrid = this.createRecipesGrid(regular);

    this.setState({ swadrecipepreviews: recipesGrid, swadfilteredrecipes: filteredRecipes, totalRecipes: `Total ${filteredRecipes.length} Results (${category.charAt(0).toUpperCase() + category.slice(1)})` });
    var firstPagePreview = recipesGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small products [1, [1, 2, 3, 4]] alternating
    this.setState({ visiblepreviews: firstPagePreview });
  }

  handleScroll(event) {
    if (document.getElementById("recipes-page")) {
      if (window.scrollY >= document.getElementById("recipes-page").offsetHeight - window.innerHeight) {
        if (this.state.isLoading === false) {
          this.setState({ isLoading: true, loadAmount: this.state.loadAmount + 4 });
          this.setState({ visiblepreviews: this.state.swadrecipepreviews.slice(0, this.state.loadAmount) })
          this.setState({ isLoading: false, loadAmount: this.state.loadAmount + 4 });

          if (this.state.swadrecipepreviews.length === this.state.visiblepreviews.length) {
            document.getElementById("loading").style.display = 'none';
          }
        }
      }
    }
  }

  setRecipeModal(id) {
    this.setState({ viewproduct: "" });

    if (this.state.isFiltered === 0) {
      this.state.swadrecipes.forEach((recipe) => {
        if (id === recipe.recipe_id) {
          this.setState({ viewrecipe: recipe });
        }
      });
    } else {
      this.state.swadfilteredrecipes.forEach((recipe) => {
        if (id === recipe.recipe_id) {
          this.setState({ viewrecipe: recipe });
        }
      });
    }
  }

  setProductModal(sku) {
    // this.setState({ viewrecipe: "" });

    this.state.swadproducts.forEach((product) => {
      if (sku === product.sku) {
        this.setState({ viewproduct: product });
      }
    });
  }

  showAllRecipes() {
    this.setState({ isFiltered: 0 });

    var recipes = this.state.swadrecipes;

    var rotwItem;
    var sortedRecipes = recipes.filter((recipe) => {
      if (recipe.recipe_id == this.state.swadrecipeofweek.props.recipe_id) {
        rotwItem = recipe;
        return false;
      }

      return true;
    });
    if (rotwItem != null) {
      sortedRecipes.unshift(rotwItem);
    }
    var regular = sortedRecipes.map((recipe) => {
      return (
        <RecipePreview showFeatured={recipe.recipe_id == this.state.swadrecipeofweek.props.recipe_id} key={recipe.recipe_id} setRecipeModal={this.setRecipeModal} recipeSize={'product-sm'} colSize={6} {...recipe} />
      )
    });

    const recipesGrid = this.createRecipesGrid(regular);

    window.addEventListener('scroll', this.handleScroll);

    this.setState({ swadrecipepreviews: recipesGrid, swadrecipes: recipes, totalRecipes: `Total ${recipes.length} Results` });
    if (recipesGrid.length > 0) {
      var firstPagePreview = recipesGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small recipes [1, [1, 2, 3, 4]] alternating
      this.setState({ visiblepreviews: firstPagePreview });
    } else {
      this.setState({ visiblepreviews: "No Results" });
    }
  }

  render() {
    if (this.state.swadrecipeofweek === "" || this.state.swadrecipes === "") {
      return (<Loading />);
    }

    return (
      <div>
        <Header />
        <div id="recipes-page">
          {/* Recipe of the Week */}
          <header className="masthead text-center text-white d-flex" style={{ backgroundImage: "url(" + this.state.swadrecipeofweek.props.bg_image_path + ")" }}>
            {this.state.swadrecipeofweek}
          </header>
          {/* End Recipe of the Week */}

          {/* Mini - Explore Our Recipes */}
          <MiniExplore type={"Recipes"} text={"SWAD ingredients can be used in recipes from all over the world."} />
          {/* End Mini - Explore Our Recipes */}

          {/* Filter Bar */}
          <FilterBar categories={this.state.swadcategories} showAll={this.showAllRecipes} filterText={this.state.filterText} handleFilterTextChange={this.handleFilterTextChange} />
          {/* End Filter Bar */}

          {/* All Recipes */}
          <VisiblePreviews totalItems={this.state.totalRecipes} visiblepreviews={this.state.visiblepreviews}></VisiblePreviews>
          {/* End All Recipes */}

          <RecipeModal setProductModal={this.setProductModal} recipe={this.state.viewrecipe} />
          <RecipeProductModal changeModalImage={this.changeModalImage} setRecipeModal={this.setRecipeModal} product={this.state.viewproduct} />

        </div>
        <Footer />
      </div>
    );
  }
}

export default RecipesPage;
