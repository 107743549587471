import React from 'react';
import Select from 'react-select';
import Picker from 'react-month-picker';
import { Modal } from 'react-bootstrap';

import MonthBox from '../containers/MonthBox';

const ProductModal = ({
  productImgFront,
  productImgBack,
  handleImageChange,
  handleAddRecipe,
  handleChange,
  handleSubmit,
  readOnly,
  product,
  allRecipes,
  productOTMimg,
  anyRecipes,
  handleDel,
  handleSearchRecipe,
  mvalue,
  handleAMonthChange,
  handleAMonthDissmis,
  handleClickMonthBox,
  pickerLang,
  makeText,
  inputRef,
  inputRef2,
  inputRef3,
  inputRef4,
  handleClose,
  handleShow,
  productShow}) => {

  const selectRecipe = allRecipes.map((recipe) => {
    recipe.value = recipe.name;
    recipe.label = recipe.name;
    return recipe;
  })

  if (product.recipes) {
    var productRecipes = product.recipes.map((recipe, idx, arr) => {
      let value = {}
      if (typeof (recipe) !== 'string') value = recipe;
      value.value = recipe.name;
      value.label = recipe.name;

      return (
        <div className="row" key={idx} style={{marginBottom: '20px'}}>
          <div className="col-sm-11">

            <Select
              name="form-field-recipes"
              value={value}
              onChange={(e) => handleSearchRecipe(e, idx)}
              options={selectRecipe}
            />

          </div>
          <div className="col-sm-1" style={{padding:'0'}}>
            <div className="pointer text-center del-step" onClick={(e) => handleDel(e, recipe, idx, arr)} >DEL</div>
          </div>
        </div>
      )
    })
  }

  if (product.productofthemonth) {
    var potmInfo =
      <div className="col-sm-12 text-center">
        <div style={{fontSize:'1.5em'}}>PRODUCT OF THE MONTH DETAILS</div>
        <br />
        <img style={{height: 500, objectFit: 'contain'}} className="w100" src={productOTMimg} alt='Product of the Month' />
        <input ref={inputRef3} type="file"  onChange={(e)=> handleImageChange(e, 'productOTM')} />
        <br />
        <br />
        <div className="col-sm-12" style={{padding:"0"}}>
          <Picker
              ref={inputRef4}
              years={[2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2028, 2030]}
              value={mvalue}
              lang={pickerLang.months}
              onChange={handleAMonthChange}
              onDismiss={handleAMonthDissmis}
                >
            <MonthBox value={makeText(mvalue)} onClick={handleClickMonthBox} />
          </Picker>
        </div>
        <br />
        <br />
        <div>DESCRIPTION</div>
        <input className="w100" required type="textarea" name="description" defaultValue={product.productofthemonth.description} onChange={(e) => handleChange(e, 'potm')} />
      </div>
  }

  return (
      <Modal show={productShow} onHide={() => handleClose('product')} id="product-modal">
        <Modal.Body>
          <form style={{padding:'2%'}} onSubmit={(e) => handleSubmit(e, 'product')}>
            <Modal.Header closeButton>
              <Modal.Title id="product-title">PRODUCT DETAILS</Modal.Title>
            </Modal.Header>
            <div style={{textAlign:'center'}} className="modal-body row">
              <div className="col-sm-6">
                <img className="w100" src={productImgFront} alt="" />
                <label for="product-main-img">Main Image:</label>
                <input id="product-main-img" ref={inputRef} type="file"  onChange={(e)=>handleImageChange(e, 'front')} accept="image/x-png"/>
              </div>
              <div className="col-sm-6">
                <img className="w100" src={productImgBack} alt="" />
                <label for="product-nutrition-img">Nutrition Label Image:</label>
                <input id="product-nutrition-img" ref={inputRef2} type="file"  onChange={(e)=>handleImageChange(e, 'back')} accept="image/x-png"/>
              </div>
              <div className="col-sm-12" style={{textAlign:'left'}}>
                <br />
                <br />
                <div>PRODUCT NAME</div>
                <input className="w100" required type="text" value={product.name} name="name" onChange={(e) => handleChange(e, "product")} />
                <br />
                <br />
                <div>BARCODE</div>
                <input className="w100" required readOnly={readOnly} type="text" value={product.barcode} name="barcode" onChange={(e) => handleChange(e, "product")} />
                <br />
                <br />
                <div>SKU</div>
                <input className="w100" required readOnly={readOnly} type="number" value={product.sku} name="sku" onChange={(e) => handleChange(e, "product")} />
                <br />
                <br />
                <div>CATEGORY</div>
                <input className="w100" required type="text" value={product.category} name="category" onChange={(e) => handleChange(e, "product")} />
                <br />
                <br />
                <div>PRODUCT SIZES</div>
                <input className="w100" required type="text" value={product.product_sizes} name="product_sizes" onChange={(e) => handleChange(e, "product")} />
              </div>
            </div>
              {potmInfo}
            <div className="col-sm-12" p>
              <div className="text-center">{anyRecipes}</div>
              {productRecipes}
            </div>
            <br />
            <br />
          <Modal.Footer>
            <button type="button" className="pointer btn-orange" onClick={handleAddRecipe}>ADD RECIPE</button>
            <button type="button" className="pointer btn-orange" onClick={() => handleClose('product')}>CANCEL</button>
            <button type="submit" className="pointer btn-black">SAVE</button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ProductModal;
