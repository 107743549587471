import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const LocationsHero = props => (
  <section>
    <div id="locations" className="full-image-block container">
      <div id="locations-details" className="postbox">
        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>
            <center><p className="top-subtitle">find us</p></center>
            <strong>SWAD LOCATIONS</strong>
          </h2>
          <p className="postinfo mt-3">
            SWAD products appeal to all individuals across the world.
            You can enjoy our products with your friends and family for any occassion.
            Find SWAD products by entering your search below.
          </p>
          <div className="row">
            <div id="location-form-submit" className="col-md-12">
              <Link to="/locations" className="btn btn-primary full-width-btn">Locate Store</Link>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  </section>
);

export default LocationsHero;
