import React from 'react';

const VisiblePreviews = props => (
  <div id="featured-products" className="container">
    <p className="semibold ml-2 mt-4">{props.totalItems}</p>
    <div className="all_products row"> {
      props.visiblepreviews.map((p, index) => {
        return(
          <div key={index} className="product-holder col-lg-6 col-sm-6">
            <div className="row">
              { p }
            </div>
          </div>
        )
      })
    }</div>

    <div className="col-md-12 mt-5" style={{textAlign: 'center'}}>
      <p id="loading" className="btn btn-secondary btn-small-padding" href="#about">Loading More Results</p>
    </div>
  </div>
);

export default VisiblePreviews;
