import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

export default class ProductOfMonth extends React.Component {
  render() {
    return (
      <div id="productofmonth" className="full-image-block container" style={{backgroundImage: "url(" + this.props.bg_image_path + ")"}}>
        <div id="productofmonth-details" className="postbox ">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>
              <center><p className="top-subtitle">product of the month</p></center>
              <strong id="product-name-label">{this.props.name}</strong>
            </h2>
            <p className="postinfo mt-3">
              {this.props.description}
            </p>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6 two-buttons-one">
                <a className="btn btn-primary full-width-btn btn-small-padding" onClick={() => this.props.setProductModal(this.props.sku)} href="_#" data-toggle="modal" data-target="#product-modal">View Product</a>
              </div>
              <div className="col-md-6 col-sm-6 col-6 two-buttons-two">
                <Link className="btn btn-secondary full-width-btn btn-small-padding" to="locations">Locate Store</Link>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}
