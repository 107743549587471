import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import DayPicker from 'react-day-picker';
import { Modal } from 'react-bootstrap';
import 'react-day-picker/lib/style.css';

const RecipeModal = ({
  readOnly,
  recipe,
  recipeImg,
  handleAddProduct,
  handleAddSteps,
  handleChange,
  handleImageChange,
  handleSubmit,
  handleSearchProduct,
  handleDel,
  handleArrChange,
  anyProducts,
  anySteps,
  recipeOTWimg,
  allProducts,
  handleWeekClick,
  weeknum,
  year,
  inputRef,
  inputRef2,
  handleClose,
  handleShow,
  recipeShow,
}) => {

  var selectProduct = allProducts.map((product) => {
    product.value = product.name;
    product.label = product.name;
    return product;
  });

  if (recipe.products) {
    var recipeProducts = recipe.products.map((product, idx, arr) => {
      let value = product;
      value.value = product.name;
      value.label = product.name;

      return (
          <div className="row" key={idx} style={{marginBottom: '20px'}}>
            <div className="col-sm-11">

              <CreatableSelect
                isClearable
                name="form-field-products"
                value={value}
                onChange={(e) => handleSearchProduct(e, idx)}
                options={selectProduct}
              />

            </div>
            <div className="col-sm-1" style={{padding:'0'}}>
              <div className="pointer text-center del-step" onClick={(e) => handleDel(e, product, idx, arr)} >DEL</div>
            </div>
          </div>
      )
    })
  }

  if (recipe.steps_mobile) {
    var recipeSteps = recipe.steps_mobile.map((step, idx, arr) => (
      <div className="row" key={idx} style={{marginBottom: '10px'}}>
        <div className="col-sm-1">
          <div style={{paddingTop:'10px'}}>{idx + 1}</div>
        </div>
        <div className="col-sm-10" style={{paddingLeft:'0'}}>
          <input className="w100" type="text" name="step" placeholder="Step Details" defaultValue={step} onChange={(e) => handleArrChange(e, idx, 'steps')} />
        </div>
        <div className="col-sm-1" style={{padding:'0'}}>
          <div className="pointer text-center del-steps" onClick={(e) => handleDel(e, step, idx, arr)}>DEL</div>
        </div>
      </div>
    ))
  }

  if (recipe.recipeoftheweek) {
    var rotwInfo =
      <div className="col-sm-12 text-center" style={{marginBottom: '30px'}}>
        <br />
        <div style={{fontSize:'1.5em'}}>RECIPE OF THE WEEK DETAILS</div>
        <br />
        <img style={{height: 500, objectFit: 'contain'}} className="w100" src={recipeOTWimg} alt="Recipe of the Week" />
        <input ref={inputRef2} type="file" onChange={(e)=> handleImageChange(e, 'recipeOTW')} />
        <div className="col-sm-12">
          <div style={{fontSize: '1em', fontWeight: 'bold'}}>Selected Week Number {weeknum} - {year}</div>
          <DayPicker
          showWeekNumbers
          onWeekClick={(week, days) => handleWeekClick(week, days)}
          />
        </div>
        <br />
        <br />
        <div>DESCRIPTION</div>
        <input className="w100" required type="textarea" name="description" defaultValue={recipe.recipeoftheweek.description} onChange={(e) => handleChange(e, 'rotw')} />
      </div>
   }

   if (recipe.ingredients) {
     var recipeIngredients = recipe.ingredients.map((ingredient, idx, arr) => (
       <div className="row" key={ingredient} style={{marginBottom: '10px'}}>
         <div className="col-sm-11">
           <input defaultValue={ingredient} className="w100 ingredient" onChange={(e) => handleArrChange(e, idx, 'ingredients')}/>
         </div>
         <div className="col-sm-1" style={{padding:'0'}}>
           <div className="pointer text-center del-step" onClick={(e) => handleDel(e, ingredient, idx, arr)}>DEL</div>
         </div>
       </div>
     ))
   }

  return (
      <Modal show={recipeShow} onHide={() => handleClose('recipe')} id="recipe-modal">
        <Modal.Body>
          <form style={{padding:'2%'}} onSubmit={(e) => handleSubmit(e, 'recipe')}>
            <Modal.Header closeButton>
              <Modal.Title id="recipe-title">RECIPE DETAILS</Modal.Title>
            </Modal.Header>
            <div style={{textAlign:'center'}} className="modal-body row">
              <div className="col-sm-12">
                {recipeImg ? <img alt="" style={{height: 500, objectFit: 'contain'}} className="w100" src={recipeImg} /> : null}
                <input ref={inputRef} type="file" onChange={(e)=>handleImageChange(e, 'recipe')} />
              </div>
              <div className="col-sm-12" style={{textAlign:'left'}}>
                <br />
                <br />
                <div>RECIPE NAME</div>
                <input className="w100" required type="text" name="name" value={recipe.name} onChange={(e) => handleChange(e, "recipe")} />
                <br />
                <br />
                <div>RECIPE DESCRIPTION</div>
                <input className="w100" required name="description" type="textarea" value={recipe.description} onChange={(e) => handleChange(e, "recipe")} />
                <br />
                <br />
              </div>
              <div className="col-sm-6">
                <div>DIFFICULTY</div>
                <input className="w100" type="text" name="difficulty" value={recipe.difficulty} onChange={(e) => handleChange(e, "recipe")} />
                <br />
                <br />
                <div>DURATION</div>
                <input className="w100" type="text" name="duration" value={recipe.duration} onChange={(e) => handleChange(e, "recipe")} />
                <br />
                <br />
              </div>
              <div className="col-sm-6">
                <div>CATEGORY</div>
                <input className="w100" required type="text" name="category" value={recipe.category} onChange={(e) => handleChange(e, "recipe")} />
                <br />
                <br />
                <div>SERVES</div>
                <input className="w100" type="text" name="amount_serves" value={recipe.amount_serves} onChange={(e) => handleChange(e, "recipe")} />
                </div>
                <input hidden className="w100" readOnly={readOnly} type="text" name="recipe_id" value={recipe.recipe_id} />
              </div>

              <div className="col-sm-12">
                <div className="text-center">{anyProducts}</div>
                {recipeIngredients}
                {recipeProducts}
              </div>

              <div className="col-sm-12">
                <br />
                <div className="text-center">{anySteps}</div>
                {recipeSteps}
                {rotwInfo}
            </div>
            <br />
            <Modal.Footer>
              <button type="button" className="pointer btn-orange" onClick={handleAddProduct}>ADD PRODUCT / INGREDIENT</button>
              <button type="button" className="pointer btn-orange" onClick={handleAddSteps}>ADD STEP</button>
              <button type="button" className="pointer btn-orange" onClick={() => handleClose('recipe')}>CANCEL</button>
              <button type="submit" className="pointer btn-black">SAVE</button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
  )
}

export default RecipeModal;
