import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class RecipeOfWeek extends React.Component {
  render() {
    return (
      <div id="recipeoftheweek" className="full-image-block container">
        <div id="recipeoftheweek-details" className="postbox">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>
              <p id="recipe-default-label" className="top-subtitle">recipe of the week</p>
              <p id="recipe-title-label">{this.props.name}</p>
            </h2>
            <p id="recipe-desc-label" className="postinfo">
              {this.props.rotw_desc}
            </p>
            <a id="recipeofweek-modal-btn" className="btn btn-primary btn-xl js-scroll-trigger" onClick={() => this.props.setRecipeModal(this.props.recipe_id)} href="_blank" data-toggle="modal" data-target="#recipe-modal">Get the Recipe</a>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}
