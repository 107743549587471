import React from 'react';
import DayPicker from 'react-day-picker';
import { Modal } from 'react-bootstrap';
import 'react-day-picker/lib/style.css';

const RecipeOfTheWeek = ({
  showROTW,
  recipeOTWimg,
  inputRef,
  handleImageChange,
  handleWeekClick,
  inputRef2,
  closeModal,
  handleSubmit,
  rotwShow,
  hoverRange,
  selectedDays,
  handleDayEnter,
  handleDayLeave,
  handleDayChange
}) => {

  const daysAreSelected = selectedDays.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };

  return (
      <Modal show={rotwShow} onHide={closeModal} id="rotw-modal">
      <Modal.Body>
        <Modal.Header closeButton>
          <Modal.Title id="potm-title">RECIPE OF THE WEEK DETAILS</Modal.Title>
        </Modal.Header>
        <form style={{padding:'2%'}} onSubmit={(e) => handleSubmit(e, 'recipeOTW')}>
          <div style={{textAlign:'center'}} className="modal-body row">
            <div className="col-sm-12">
              {recipeOTWimg ? <img style={{height: 500, objectFit: 'contain'}} className="w100" src={recipeOTWimg} alt="Recipe of the Week" /> : null }
              <input ref={inputRef} type="file"  onChange={(e)=> handleImageChange(e, 'recipeOTW')} accept="image/*" />
              <br />
              <br />
              <div>PICK A WEEK</div>
              <DayPicker
                showWeekNumbers
                onWeekClick={(week, days) => handleWeekClick(week, days)}
                selectedDays={selectedDays}
                modifiers={modifiers}
                onDayClick={handleDayChange}
                onDayMouseEnter={handleDayEnter}
                onDayMouseLeave={handleDayLeave}
                />
            </div>
            <div className="col-sm-12" style={{textAlign:'left'}}>
              <br />
              <br />
              <div>DESCRIPTION</div>
              <input className="w100" required type="textarea" ref={inputRef2} />
            </div>
          </div>
        <Modal.Footer>
          <button type="button" className="pointer btn-orange" onClick={closeModal}>CANCEL</button>
          <button type="submit" className="pointer btn-black">SAVE</button>
        </Modal.Footer>
       </form>
       <br />
       <br />
      </Modal.Body>
    </Modal>
  )
}

export default RecipeOfTheWeek;
