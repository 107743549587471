import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class RecipePreview extends React.Component {
  render() {
    return (
      <div className={`${this.props.recipeSize} col-lg-${this.props.colSize} col-sm-${this.props.colSize}`}>
        <a onClick={() => this.props.setRecipeModal(this.props.recipe_id)} href="_#" data-toggle="modal" data-target="#recipe-modal" style={{width: "100%"}}>
          {this.props.showFeatured ? <ScrollAnimation animateOnce={true} animateIn="fadeIn" className="featured" style={{width:'210px'}}><p>Featured</p></ScrollAnimation> : null}

          <ScrollAnimation animateOnce={true} animateIn="fadeIn" className="product" style={{backgroundImage: "url(" + this.props.image_path + ")", backgroundSize:"cover", backgroundPosition:'center'}}>
            <div className="bottom-overlay"></div>
          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" className="product-details"><div onClick={() => this.props.setRecipeModal(this.props.recipe_id)} className="secondary-a" href="_#" data-toggle="modal" data-target="#recipe-modal">{this.props.name}</div></ScrollAnimation>
        </a>
      </div>
    );
  }
}
