import React from 'react';
import Picker from 'react-month-picker';
import { Modal } from 'react-bootstrap';

import MonthBox from '../containers/MonthBox';

const ProductOfTheMonth = ({
  showPOTM,
  productOTMimg,
  handleAMonthChange,
  handleAMonthDissmis,
  handleClickMonthBox,
  handleImageChange,
  handleSubmit,
  inputRef,
  inputRef2,
  mvalue,
  closeModal,
  inputRef3,
  pickerLang,
  makeText,
  potmShow}) => {

  return (
      <Modal show={potmShow} onHide={closeModal} id="potm-modal">
        <Modal.Body>
          <form style={{padding:'2%'}} onSubmit={(e) => handleSubmit(e, 'productOTM')}>
            <Modal.Header closeButton>
              <Modal.Title id="potm-title">PRODUCT OF THE MONTH DETAILS</Modal.Title>
            </Modal.Header>
            <div style={{textAlign:'center'}} className="modal-body row">
              <div className="col-sm-12">
                {productOTMimg ? <img style={{height: 500, objectFit: 'contain'}} className="w100" src={productOTMimg} alt='Product of the Month' /> : null}
                <input ref={inputRef} type="file"  onChange={(e)=>handleImageChange(e, 'productOTM')} accept="image/*"/>
                <br />
                <br />
                <div>PICK A MONTH</div>
                <Picker
                  ref={inputRef3}
                  years={{min: 2019, max: 2030}}
                  value={mvalue}
                  lang={pickerLang.months}
                  onChange={handleAMonthChange}
                  onDismiss={handleAMonthDissmis}
                  >
                  <MonthBox value={makeText(mvalue)} onClick={handleClickMonthBox} />
                </Picker>
              </div>
            <div className="col-sm-12" style={{textAlign:'left'}}>
              <br />
              <div className="text-center">DESCRIPTION</div>
              <input className="w100" required type="textarea" ref={inputRef2} />
            </div>
          </div>
          <Modal.Footer>
            <button type="button" className="pointer btn-orange" onClick={closeModal}>CANCEL</button>
            <button type="submit" className="pointer btn-black">SAVE</button>
          </Modal.Footer>
        </form>
        <br />
        <br />
      </Modal.Body>
    </Modal>
  )
}

export default ProductOfTheMonth;
