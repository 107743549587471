import React from 'react';
import gallery1 from '../../../images/gallery/gallery1.jpg';
import gallery2 from '../../../images/gallery/gallery2.jpg';
import gallery3 from '../../../images/gallery/gallery3.jpg';
import gallery4 from '../../../images/gallery/gallery4.jpg';

const MiniGallery = props => (
  <section id="mini-gallery" className="container bg-primary">
    <div className="row">
      <div className="col-lg-3 col-sm-3" alt="gallery1" >
        <img src={gallery1} alt="gallery1" />
      </div>
      <div className="col-lg-3 col-sm-3">
        <img src={gallery2} alt="gallery2" />
      </div>
      <div className="col-lg-3 col-sm-3">
        <img src={gallery3} alt="gallery3" />
      </div>
      <div className="col-lg-3 col-sm-3">
        <img src={gallery4} alt="gallery4" />
      </div>
    </div>
  </section>
);

export default MiniGallery;
