import React from 'react';
import logo from '../../images/logo.png';

const LeftNav = ({addNew}) => {
  
  const home = () => {
    window.location.href = '/';
  }

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  }

  return (
    <div className="col-sm-2" id="admin-left">
      <img onClick={home} className="pointer" src={logo} id="left-logo" alt="logo" />
      <div style={{paddingLeft:'15%'}}>
        <div onClick={home} className="pointer" >HOME PAGE</div>
        <div onClick={() => addNew('store')} className="pointer">ADD STORE</div>
        <div onClick={() => addNew('product')} className="pointer">ADD PRODUCT</div>
        <div onClick={() => addNew('recipe')} className="pointer">ADD RECIPE</div>
        <div onClick={logout} className="pointer">LOGOUT</div>
      </div>
    </div>
  )
}

export default LeftNav;