import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../../images/logo.png'

export default class Header extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div className="top-gradient"></div>
        <div className="container">
          <Link to="/" className="navbar-brand js-scroll-trigger"><img alt="" className="logo" src={logo} /></Link>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink exact to="/" className="nav-link js-scroll-trigger" activeStyle={{ borderBottom: '1px solid white', color: 'white' }} style={{ color: 'white !important'}}>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/locations" className="nav-link js-scroll-trigger" activeStyle={{borderBottom: '1px solid white', color: 'white'}}>Locations</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/products" className="nav-link js-scroll-trigger" activeStyle={{borderBottom: '1px solid white', color: 'white' }}>Products</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/recipes" className="nav-link js-scroll-trigger" activeStyle={{borderBottom: '1px solid white', color: 'white' }}>Recipes</NavLink>
              </li>
              {/*<li className="nav-item pointer" data-toggle="modal" data-target="#loginModal">
                <div to="/" className="nav-link" style={{textTransform:"uppercase", color: "white", fontWeight:"700", lineSpacing: '2px', fontSize: "14px"}}activeStyle={{borderBottom: '1px solid white'}}>Login</div>
              </li>*/}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
