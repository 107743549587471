import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ScrollAnimation from 'react-animate-on-scroll';

const MiniExplore = props => (
  <section className="bg-primary">
    <div className="container text-center">
      <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <h2 className="section-heading" style={{paddingBottom:'10px'}}>Explore Our {props.type}</h2>
        <p>{ReactHtmlParser(props.text)}</p>
      </ScrollAnimation>
    </div>
  </section>
);

export default MiniExplore;
