import React from 'react';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import CSVReader from 'react-csv-reader'

const StoreModal = ({
  readOnly,
  store,
  newStore,
  uploadingCSVStores,
  handleChange,
  handleSubmit,
  handleCSVSubmit,
  handleChangeState,
  handleClose,
  handleShow,
  storeShow}) => {

  const states = [
    { value: 'Alabama', label: 'AL - Alabama' },
    { value: 'Alaska', label: 'AK - Alaska' },
    { value: 'Arizona', label: 'AZ - Arizona' },
    { value: 'Arkansas', label: 'AR - Arkansas' },
    { value: 'California', label: 'CA - California' },
    { value: 'Colorado', label: 'CO - Colorado' },
    { value: 'Connecticut', label: 'CT - Connecticut' },
    { value: 'Delaware', label: 'DE - Delaware' },
    { value: 'District Of Columbia', label: 'DC - District Of Columbia' },
    { value: 'Florida', label: 'FL - Florida' },
    { value: 'Georgia', label: 'GA - Georgia' },
    { value: 'Hawaii', label: 'HI - Hawaii' },
    { value: 'Idaho', label: 'ID - Idaho' },
    { value: 'Illinois', label: 'IL - Illinois' },
    { value: 'Indiana', label: 'IN - Indiana' },
    { value: 'Iowa', label: 'IA - Iowa' },
    { value: 'Kansas', label: 'KA - Kansas' },
    { value: 'Kentucky', label: 'KY - Kentucky' },
    { value: 'Louisiana', label: 'LA - Louisiana' },
    { value: 'Maine', label: 'ME - Maine' },
    { value: 'Maryland', label: 'MD - Maryland' },
    { value: 'Massachusetts', label: 'MA - Massachusetts' },
    { value: 'Michigan', label: 'MI - Michigan' },
    { value: 'Minnesota', label: 'MN - Minnesota' },
    { value: 'Mississippi', label: 'MS - Mississippi' },
    { value: 'Missouri', label: 'MO - Missouri' },
    { value: 'Montana', label: 'MT - Montana' },
    { value: 'Nebraska', label: 'NE - Nebraska' },
    { value: 'Nevada', label: 'NV - Nevada' },
    { value: 'New Hampshire', label: 'NH - New Hampshire' },
    { value: 'New Jersey', label: 'NJ - New Jersey' },
    { value: 'New Mexico', label: 'NM - New Mexico' },
    { value: 'New York', label: 'NY - New York' },
    { value: 'North Carolina', label: 'NC - North Carolina' },
    { value: 'North Dakota', label: 'SC - North Dakota' },
    { value: 'Ohio', label: 'OH - Ohio' },
    { value: 'Oklahoma', label: 'OK - Oklahoma' },
    { value: 'Oregon', label: 'OR - Oregon' },
    { value: 'Pennsylvania', label: 'PA - Pennsylvania' },
    { value: 'Rhode Island', label: 'RI - Rhode Island' },
    { value: 'South Carolina', label: 'SC - South Carolina' },
    { value: 'South Dakota', label: 'SD - South Dakota' },
    { value: 'Tennessee', label: 'TN - Tennessee' },
    { value: 'Texas', label: 'TX - Texas' },
    { value: 'Utah', label: 'UT - Utah' },
    { value: 'Vermont', label: 'VT - Vermont' },
    { value: 'Virginia', label: 'VA - Virginia' },
    { value: 'Washington', label: 'WA - Washington' },
    { value: 'West Virginia', label: 'WV - West Virginia' },
    { value: 'Wisconsin', label: 'WI - Wisconsin' },
    { value: 'Wyoming', label: 'WY - Wyoming' },
  ]

  var selectedState = {value: store.state};
  for(let i = 0; i < states.length; i++){
    if (states[i].value == store.state) {
      selectedState.label = states[i].label;
    }
  }


  return (
    <Modal show={storeShow} onHide={() => handleClose('store')} id="store-modal">
      {uploadingCSVStores ?
        (
          <Modal.Body>
              <Modal.Header closeButton>
                <Modal.Title id="store-title">STORE DETAILS</Modal.Title>
              </Modal.Header>
              <div style={{textAlign:'center'}} className="modal-body row">
                <div className="col-sm-12">
                  Uploading Stores from CSV, Please Wait...
                </div>
              </div>
              <br />
              <br />
          </Modal.Body>
        )
        :
        (
          <Modal.Body>
            <form style={{padding:'2%'}} onSubmit={(e) => handleSubmit(e, 'store')}>
              <Modal.Header closeButton>
                <Modal.Title id="store-title">STORE DETAILS</Modal.Title>
              </Modal.Header>
              <div style={{textAlign:'center'}} className="modal-body row">
                <div className="col-sm-6" style={{textAlign:'left'}}>
                  <div>STORE NO.</div>
                  <input className="w100" readOnly={readOnly} required type="number" value={store.store_id} name="store_id" onChange={(e) => handleChange(e, "store")}/>
                  <br />
                  <br />
                  <div>STORE NAME</div>
                  <input className="w100" required type="text" value={store.name} name="name" onChange={(e) => handleChange(e, "store")} />
                  <br />
                  <br />
                  <div>PHONE NUMBER</div>
                  <input className="w100" required type="text" value={store.phone_number} name="phone_number" onChange={(e) => handleChange(e, "store")} />
                  <br />
                  <br />
                  <div><span>STORE TIMES </span><span style={{color:'grey'}}> (SEPERATE BY COMMA)</span></div>
                  <input className="w100" required type="text" placeholder="Mon-Fri 8AM-9:30PM, Sat-Sun 9AM-10:30PM" value={store.store_times} name="store_times" onChange={(e) => handleChange(e, "store")} />
                </div>
                <div className="col-sm-6" style={{textAlign:'left'}}>
                  <div>STREET</div>
                  <input className="w100" required type="text" value={store.street} name="street" onChange={(e) => handleChange(e, "store")} />
                  <br />
                  <br />
                  <div>CITY</div>
                  <input className="w100" required type="text" value={store.city} name="city" onChange={(e) => handleChange(e, "store")} />
                  <br />
                  <br />
                  <div>STATE</div>
                  <Select
                    required
                    className="from-state"
                    name="form-field-state"
                    value={[selectedState]}
                    onChange={handleChangeState}
                    options={states}
                    />
                  <br />
                  <div>ZIPCODE</div>
                  <input className="w100" required type="text" value={store.zipcode} name="zipcode" onChange={(e) => handleChange(e, "store")} />
                </div>
              </div>
              <br />
              <br />
              <Modal.Footer>
                <button type="button" className="pointer btn-orange" onClick={() => handleClose('store')}>CANCEL</button>
                <button type="submit" className="pointer btn-black">SAVE</button>
              </Modal.Footer>
            </form>

            { newStore ?
              <center>
                <CSVReader
                 cssClass="csv-input"
                 label="Or Upload A CSV File"
                 onFileLoaded={handleCSVSubmit}
               />
               </center>
              : null
            }
          </Modal.Body>
        )
      }
    </Modal>
  )
}

export default StoreModal;
