import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const MiniInstagram = props => (
  <section id="instagram" className="container bg-primary">
    <div className="text-center">
      <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <a className="secondary-a-dark" href="https://www.instagram.com/swadfoodproducts" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
        <h2>
          Get Inspired with Special Tips, Ideas and Recipes Made by You!<br/>
          <span className="highlight-text">good friends. good food. good laughs</span><br/>
          Check out our Instagram Today!
        </h2>
      </ScrollAnimation>
    </div>
  </section>
);

export default MiniInstagram;
