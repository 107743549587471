import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import Header from '../components/generic/Header';
import Loading from '../components/generic/Loading';
import ProductOfMonth from '../components/products/ProductOfMonth';
import MiniExplore from '../components/generic/MiniExplore';
import FilterBar from '../components/filter/FilterBar';
import VisiblePreviews from '../components/generic/VisiblePreviews';
import ProductPreview from '../components/products/ProductPreview';
import ProductModal from '../components/modals/ProductModal';
import ProductRecipeModal from '../components/modals/ProductRecipeModal';
import Footer from '../components/generic/Footer';
import AdminLogin from '../../Admin/containers/AdminLogin';

class ProductsPage extends Component {
  constructor() {
    super();
    this.state = {
      swadproducts: "",
      swadrecipes: "",
      swadcategories: "",
      isFiltered: 0,
      swadfilteredproducts: [],
      swadproductpreviews: [],
      filterText: "",
      visiblepreviews: [],
      perPage: 10,
      isLoading: false,
      loadAmount: 4,
      sortByText: "All",
      totalProducts: "Total 0 Results",
      viewproduct: "",
      viewrecipe: "",
      swadproductofmonth: ""
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.filterTextBySearchBar = this.filterTextBySearchBar.bind(this);
    this.setProductModal = this.setProductModal.bind(this);
    this.setRecipeModal = this.setRecipeModal.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.sortByCategory = this.sortByCategory.bind(this);
    this.showAllProducts = this.showAllProducts.bind(this);
    this.changeModalImage = this.changeModalImage.bind(this);

    document.addEventListener('contextmenu', this.handleContextMenu);
  }

  handleContextMenu(event) {
    event.preventDefault();
  }

  createProductsGrid(productPreviewList) {
    var productsGrid = [];
    var tempRegular = productPreviewList.slice();

    for (var i = 0; i < productPreviewList.length; i += 5) {
      var largeProduct = null;

      if (tempRegular[0].props.feature_tag === null) {
        var checkFeatured = tempRegular.filter((productView) => productView.props.feature_tag !== null);
        if (checkFeatured.length > 0) {
          largeProduct = checkFeatured[0];
          var removeIndex = tempRegular.map(function (item) { return item.props.sku; }).indexOf(largeProduct.props.sku);
          tempRegular.splice(removeIndex, 1);
        }
      }

      if (!largeProduct) {
        largeProduct = tempRegular.shift();
      }

      let largeProductObj = { ...largeProduct, props: { ...largeProduct.props, colSize: "12", productSize: "product-lg"}}
      largeProduct = largeProductObj;

      if (i % 2 === 0) {
        productsGrid.push(largeProduct, tempRegular.splice(0, 4));

      } else {
        if (tempRegular.length === 1) {
          tempRegular.unshift((<div className='col-lg-6 col-sm-6'></div>));
          productsGrid.push(tempRegular.splice(0, 4), largeProduct);

        } else if (tempRegular.length === 3) {
          tempRegular.splice(2, 0, (<div className='col-lg-6 col-sm-6'></div>));
          productsGrid.push(tempRegular.splice(0, 4), largeProduct);

        } else {
          productsGrid.push(tempRegular.splice(0, 4), largeProduct);
        }
      }
    }

    return productsGrid;
  }

  componentDidMount() {
    var filterCategories = []; //to filter by category name
    var categories = [];

    axios.get('/api/all_products')
      .then((results) => {
	const getProducts = results.data;
        const products = getProducts.filter((p) => {
          if (p.category != null) {
            if (p.category.toLowerCase().includes("organic")) {
              return false
            }
          }
	  
	  if (p.name.toLowerCase().includes("organic") || p.name.toLowerCase().includes("ghee")) {
              return false
          }

          return true
        });	

        const today = new Date();

        var usePOTM;
        var thisYearsPOTM = products.filter((product) => product.productofthemonth !== null && String(product.productofthemonth.year) === String(today.getFullYear()));
        if (thisYearsPOTM.length > 0) {
          const checkMonthIsThere = thisYearsPOTM.filter((product) => String(product.productofthemonth.month) == String(today.getMonth()+1));
          if (checkMonthIsThere.length > 0) {
            usePOTM = checkMonthIsThere.map((p) => {
              return <ProductOfMonth hasBackground={true} description={p.productofthemonth.description} bg_image_path={p.productofthemonth.image_path ? p.productofthemonth.image_path : p.image_path} setProductModal={this.setProductModal} key={p.productofthemonth.month} {...p} />;
            })[0];

          } else {
            const checkPrevMonthsThisYear = thisYearsPOTM.filter((product) => product.productofthemonth.month > (today.getMonth()+1)).sort((a, b) => b.productofthemonth.month - a.productofthemonth.month);
            if (checkPrevMonthsThisYear.length > 0) {
              usePOTM = checkPrevMonthsThisYear.map((p) => {
                return <ProductOfMonth hasBackground={true} description={p.productofthemonth.description} bg_image_path={p.productofthemonth.image_path ? p.productofthemonth.image_path : p.image_path} setProductModal={this.setProductModal} key={p.productofthemonth.month} {...p} />;
              })[0];
            }
          }
        } else {
          usePOTM = products.filter((product) => product.productofthemonth !== null && product.productofthemonth.month >= (today.getMonth()+1)).sort((a, b) => b.productofthemonth.month - a.productofthemonth.month).map((p) => {
            return <ProductOfMonth hasBackground={true} description={p.productofthemonth.description} bg_image_path={p.productofthemonth.image_path ? p.productofthemonth.image_path : p.image_path} setProductModal={this.setProductModal} key={p.productofthemonth.month} {...p} />;
          })[0];
        }

        if (!usePOTM) {
          usePOTM = (<ProductOfMonth key={0} hasBackground={true} bg_image_path={products[0].image_path} description={"Check out one of our favorite products!"} setProductModal={this.setProductModal} {...products[0]} />);
        }
        this.setState({ swadproductofmonth: usePOTM });

        var potmProduct;
        var productsWithImage = products.filter((product) => {
          if (product.barcode == usePOTM.props.barcode) {
            potmProduct = product;
            return false;
          }
          return product.image_path !== null
        });
        var productsWithoutImage = products.filter((product) => {
          if (product.barcode == usePOTM.props.barcode) {
            potmProduct = product;
            return false;
          }

          return product.image_path == null
        });
        var allProductsImageFirst = productsWithImage.concat(productsWithoutImage);

        //Always have the prodcut of the month first in the list
        if (potmProduct != null) {
          allProductsImageFirst.unshift(potmProduct);
        }

        var regular = allProductsImageFirst.map((product) => {
          if (product.category != null) {
            if (!filterCategories.includes(product.category)) {
              filterCategories.push(product.category);
              categories.push(<a className="dropdown-item" onClick={(e) => this.sortByCategory(e)}>{product.category}</a>);
            }
          }

          return (
            <ProductPreview key={product.sku} showFeatured={product.barcode === potmProduct.barcode} setProductModal={this.setProductModal} productSize={'product-sm'} colSize={6} {...product} />
          )
        });

        const productsGrid = this.createProductsGrid(regular);

        window.addEventListener('scroll', this.handleScroll);

        this.setState({ swadproductpreviews: productsGrid, swadproducts: products, swadcategories: categories, totalProducts: `Total ${products.length} Results` });
        var firstPagePreview = productsGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small products [1, [1, 2, 3, 4]] alternating
        this.setState({ visiblepreviews: firstPagePreview });

        if (this.props.match.params.category) {
            this.sortByCategory();
        }
      });

    axios.get('/api/all_recipes')
      .then((results) => {
        var recipes = results.data;
        this.setState({ swadrecipes: recipes });
      });
  }

  handleFilterTextChange(filterText) {
    this.setState({
      filterText: filterText
    });
    this.filterTextBySearchBar(filterText)
  }

  filterTextBySearchBar(filterText) {
    if (filterText === "") {
      this.showAllProducts();
      return;
    }

    this.setState({ isFiltered: 1 });

    var filteredProducts = []
    this.state.swadproducts.forEach(function (product) {
      if (product.name.toLowerCase().indexOf(filterText.toLowerCase()) === -1) {
        return;
      }

      filteredProducts.push(product);
    });

    var potmProduct;
    var productsWithImage = filteredProducts.filter((product) => {
      if (product.barcode == this.state.swadproductofmonth.props.barcode) {
        potmProduct = product;
        return false;
      }
      return product.image_path !== null
    });
    var productsWithoutImage = filteredProducts.filter((product) => {
      if (product.barcode == this.state.swadproductofmonth.props.barcode) {
        potmProduct = product;
        return false;
      }

      return product.image_path == null
    });
    var allProductsImageFirst = productsWithImage.concat(productsWithoutImage);
    //Always have the prodcut of the month first in the list, if it is found in filter only
    if (potmProduct != null) {
      allProductsImageFirst.unshift(potmProduct);
    }


    var allProducts = allProductsImageFirst.map((product) => {
      var showFeatured = false;
      if (potmProduct != null) {
        if (product.barcode == potmProduct.barcode) {
          showFeatured = true;
        }
      }
      return (
        <ProductPreview key={product.sku} showFeatured={showFeatured} setProductModal={this.setProductModal} productSize={'product-sm'} colSize={6} {...product} />
      )
    });

    const productsGrid = this.createProductsGrid(allProducts);

    this.setState({ swadproductpreviews: productsGrid, swadfilteredproducts: filteredProducts, totalProducts: `Total ${filteredProducts.length} Results` });
    var firstPagePreview = productsGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small products [1, [1, 2, 3, 4]] alternating
    this.setState({ visiblepreviews: firstPagePreview });
  }

  handleScroll(event) {
    if (document.getElementById("products-page")) {
      if (window.scrollY >= document.getElementById("products-page").offsetHeight - window.innerHeight) {
        if (this.state.isLoading === false) {
          this.setState({ isLoading: true, loadAmount: this.state.loadAmount + 4 });
          this.setState({ visiblepreviews: this.state.swadproductpreviews.slice(0, this.state.loadAmount) })
          this.setState({ isLoading: false, loadAmount: this.state.loadAmount + 4 });

          if (this.state.swadproductpreviews.length === this.state.visiblepreviews.length) {
            document.getElementById("loading").style.display = 'none';
          }
        }
      }
    }
  }

  setProductModal(sku) {
    this.setState({ viewrecipe: "" });

    if (this.state.isFiltered === 0) {
      this.state.swadproducts.forEach((product) => {
        if (String(sku) === String(product.sku)) {
          this.setState({ viewproduct: product });
        }
      });

    } else {
      this.state.swadfilteredproducts.forEach((product) => {
        if (String(sku) === String(product.sku)) {
          this.setState({ viewproduct: product });
        }
      });
    }
  }

  setRecipeModal(id) {
    // this.setState({ viewproduct: "" });
   // $('#product-modal').modal('hide');

    this.state.swadrecipes.forEach((recipe) => {
      if (id === recipe.recipe_id) {
        this.setState({ viewrecipe: recipe });
      }
    });
  }

  changeModalImage(image) {
    $('#main-product-image').attr('src', image);
  }

  showAllProducts() {
    this.setState({ isFiltered: 0, sortByText: 'All' });
    var products = this.state.swadproducts;

    var potmProduct;
    var productsWithImage = products.filter((product) => {
      if (product.barcode == this.state.swadproductofmonth.props.barcode) {
        potmProduct = product;
        return false;
      }
      return product.image_path !== null
    });
    var productsWithoutImage = products.filter((product) => {
      if (product.barcode == this.state.swadproductofmonth.props.barcode) {
        potmProduct = product;
        return false;
      }

      return product.image_path == null
    });
    var allProductsImageFirst = productsWithImage.concat(productsWithoutImage);
    //Always have the prodcut of the month first in the list
    allProductsImageFirst.unshift(potmProduct);

    var regular = allProductsImageFirst.map((product) => {
      return (
        <ProductPreview key={product.sku} showFeatured={product.barcode === potmProduct.barcode} setProductModal={this.setProductModal} productSize={'product-sm'} colSize={6} {...product} />
      )
    });

    const productsGrid = this.createProductsGrid(regular);

    window.addEventListener('scroll', this.handleScroll);

    this.setState({ swadproductpreviews: productsGrid, swadproducts: products, totalProducts: `Total ${products.length} Results` });
    if (productsGrid.length > 0) {
      var firstPagePreview = productsGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small products [1, [1, 2, 3, 4]] alternating
      this.setState({ visiblepreviews: firstPagePreview });
    } else {
      this.setState({ visiblepreviews: "No Results" });
    }
  }

  sortByCategory(e) {
    this.setState({ isFiltered: 1 });

    var category;
    if (e == null) {
        category = decodeURIComponent(this.props.match.params.category).toLowerCase();
    } else {
      category = $(e.target).text().toLowerCase();
    }
    var filteredProducts = this.state.swadproducts.filter(product => {
      if (product.category == null) {
        return false;
      }
      return product.category.toLowerCase() === category
    });

    var potmProduct;
    var productsWithImage = filteredProducts.filter((product) => {
      if (product.barcode == this.state.swadproductofmonth.props.barcode) {
        potmProduct = product;
        return false;
      }
      return product.image_path !== null
    });
    var productsWithoutImage = filteredProducts.filter((product) => {
      if (product.barcode == this.state.swadproductofmonth.props.barcode) {
        potmProduct = product;
        return false;
      }

      return product.image_path == null
    });
    var allProductsImageFirst = productsWithImage.concat(productsWithoutImage);
    //Always have the prodcut of the month first in the list, if it is found in filter only
    if (potmProduct != null) {
      allProductsImageFirst.unshift(potmProduct);
    }

    var regular = allProductsImageFirst.map((product) => {
      var showFeatured = false;
      if (potmProduct != null) {
        if (product.barcode == potmProduct.barcode) {
          showFeatured = true;
        }
      }

      return (
        <ProductPreview key={product.sku} showFeatured={showFeatured} setProductModal={this.setProductModal} productSize={'product-sm'} colSize={6} {...product} />
      )
    });

    const productsGrid = this.createProductsGrid(regular);

    this.setState({ swadproductpreviews: productsGrid, swadfilteredproducts: filteredProducts, totalProducts: `Total ${filteredProducts.length} Results (${category.charAt(0).toUpperCase() + category.slice(1)})` });
    var firstPagePreview = productsGrid.slice(0, this.state.loadAmount); //4 because this array has 1 big product and an array of 4 small products [1, [1, 2, 3, 4]] alternating
    this.setState({ visiblepreviews: firstPagePreview });

  }

  render() {
    if (this.state.swadproductofmonth === "" || this.state.swadproducts === "") {
      return (<Loading />);
    }

    return (
      <div>
        <Header />
        <div id="products-page">
          {/* Product of the Month */}
          <header className="masthead text-center text-white d-flex" style={{ backgroundImage: "url(" + this.state.swadproductofmonth.props.productofthemonth.image_path + ")" }}>
            {this.state.swadproductofmonth}
          </header>
          {/* End Product of the Month */}

          {/* Mini - Explore Our Products */}
          <MiniExplore type={"Products"} text={"At Swad, we bring you the authentic flavors of India, through our high-quality<br/>pulses, masalas, ready-to-eats & more, to tantalize your taste buds."} />
          {/* End Mini - Explore Our Products */}

          {/* Filter Bar */}
          <FilterBar categories={this.state.swadcategories} showAll={this.showAllProducts} filterText={this.state.filterText} handleFilterTextChange={this.handleFilterTextChange} />
          {/* End Filter Bar */}

          {/* All Products */}
          <VisiblePreviews totalItems={this.state.totalProducts} visiblepreviews={this.state.visiblepreviews} />
          {/* End All Products */}

          <ProductModal changeModalImage={this.changeModalImage} setRecipeModal={this.setRecipeModal} product={this.state.viewproduct} />
          <ProductRecipeModal recipe={this.state.viewrecipe} setProductModal={this.setProductModal} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default ProductsPage;
