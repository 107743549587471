import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const OurSpices = props => (
  <section>
    <div id="ourspices" className="full-image-block container">
      <div className="row">
        <div id="ourspices-details" className="col-xl-4 col-lg-6">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2 className="section-heading">
              Our Spices
            </h2>
            <p id="recipe-desc-label" className="postinfo mb-4">
              A large exotic selection of spices commonly used throughout Asia and the Middle East.
              Everything you need to create the perfect dish time and again. Most items available in multiple sizes to suit your needs.
            </p>
          </ScrollAnimation>
        </div>
      </div>
      <div className="row">
	<div style={{width: '100%'}}>
        <div id="ourspices-box" className="col-xl-4 col-lg-6 text-white">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>
              The secret of happiness is variety,<br/>
              but the secret of variety,<br/>
              like the secret of all spices, <br/>
              <span id="ourspices-special">knowing when to use it.</span>
            </p>
          </ScrollAnimation>
        </div>
        <div id="ourspices-btn-container" className="col-xl-4 col-lg-6">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <Link id="ourspices-btn" className="btn btn-primary btn-xl js-scroll-trigger" to="/products">See All Our Products</Link>
          </ScrollAnimation>
        </div>
	</div>
      </div>
    </div>
  </section>
);

export default OurSpices;
