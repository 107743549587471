import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const EatingTogether = props => (
  <section>
    <div id="eatingtogether" className="container text-white text-center">
      <div className="row">
        <div id="eatingtogether-details" className="col-lg-12">
          <center>
            <h2 id="eatingtogether-header" className="section-heading">
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                there is no love sincerer than the love of food
                <p id="eatingtogether-content">Get together with family and friends and share the taste of close-to-home Indian cooking with Swad Food Products!</p>
              </ScrollAnimation>
              <div style={{width: '100%', right: 0, height: '70%'}} className="bottom-overlay"></div>
            </h2>
          </center>
        </div>
      </div>
    </div>
  </section>
);

export default EatingTogether;
