import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Script from 'react-load-script';
import axios from 'axios';
import $ from 'jquery';
import Header from '../components/generic/Header';
import Loading from '../components/generic/Loading';
import Footer from '../components/generic/Footer';
import AdminLogin from '../../Admin/containers/AdminLogin';

import eattogether from '../../images/swadlocations.jpg';

const createReactClass = require('create-react-class');
var zipcodes = require('zipcodes');
var all_markers = [];
var activeInfoWindow;
var map;

const statesAbbr = [
  { value: 'Alabama', abbr: 'AL' },
  { value: 'Alaska', abbr: 'AK' },
  { value: 'Arizona', abbr: 'AZ' },
  { value: 'Arkansas', abbr: 'AR' },
  { value: 'California', abbr: 'CA' },
  { value: 'Colorado', abbr: 'CO' },
  { value: 'Connecticut', abbr: 'CT' },
  { value: 'Delaware', abbr: 'DE' },
  { value: 'District Of Columbia', abbr: 'DC' },
  { value: 'Florida', abbr: 'FL' },
  { value: 'Georgia', abbr: 'GA' },
  { value: 'Hawaii', abbr: 'HI' },
  { value: 'Idaho', abbr: 'ID' },
  { value: 'Illinois', abbr: 'IL' },
  { value: 'Indiana', abbr: 'IN' },
  { value: 'Iowa', abbr: 'IA' },
  { value: 'Kansas', abbr: 'KA' },
  { value: 'Kentucky', abbr: 'KY' },
  { value: 'Louisiana', abbr: 'LA' },
  { value: 'Maine', abbr: 'ME' },
  { value: 'Maryland', abbr: 'MD' },
  { value: 'Massachusetts', abbr: 'MA' },
  { value: 'Michigan', abbr: 'MI' },
  { value: 'Minnesota', abbr: 'MN' },
  { value: 'Mississippi', abbr: 'MS' },
  { value: 'Missouri', abbr: 'MO' },
  { value: 'Montana', abbr: 'MT' },
  { value: 'Nebraska', abbr: 'NE' },
  { value: 'Nevada', abbr: 'NV' },
  { value: 'New Hampshire', abbr: 'NH' },
  { value: 'New Jersey', abbr: 'NJ' },
  { value: 'New Mexico', abbr: 'NM' },
  { value: 'New York', abbr: 'NY' },
  { value: 'North Carolina', abbr: 'NC' },
  { value: 'North Dakota', abbr: 'SC' },
  { value: 'Ohio', abbr: 'OH' },
  { value: 'Oklahoma', abbr: 'OK' },
  { value: 'Oregon', abbr: 'OR' },
  { value: 'Pennsylvania', abbr: 'PA' },
  { value: 'Rhode Island', abbr: 'RI' },
  { value: 'South Carolina', abbr: 'SC' },
  { value: 'South Dakota', abbr: 'SD' },
  { value: 'Tennessee', abbr: 'TN' },
  { value: 'Texas', abbr: 'TX' },
  { value: 'Utah', abbr: 'UT' },
  { value: 'Vermont', abbr: 'VT' },
  { value: 'Virginia', abbr: 'VA' },
  { value: 'Washington', abbr: 'WA' },
  { value: 'West Virginia', abbr: 'WV' },
  { value: 'Wisconsin', abbr: 'WI' },
  { value: 'Wyoming', abbr: 'WY' },
];

const StateRow = createReactClass({
  render: function () {
    return (<div id={this.props.state.replace(/ /g, "_")} className="state-name-container"><p className="state-name"><b>{this.props.state}</b></p></div>);
  }
});

const StoreDetailRow = createReactClass({
  render: function () {
    return (
      <div className='row' style={{ marginTop: 30 }}>
        <div className="col-md-8 col-sm-8 col-8">
          <p className="store_addresses" style={{ fontWeight: '600' }}>
            {this.props.location.name} <br />
            {this.props.location.street}<br />
            {this.props.location.city}, {this.props.location.state} {this.props.location.zipcode}<br />
            {this.props.location.phone_number}
          </p>
          <a href={'https://www.google.com/maps/?q=' + this.props.location.name.replace(/ /g, "+") + '+' + this.props.location.street.replace(/ /g, "+") + '+' + this.props.location.city.replace(/ /g, "+") + '+' + this.props.location.state.replace(/ /g, "+") + '+' + this.props.location.zipcode} target="blank">
            Get Directions
            </a>
        </div>
        <div className="col-md-4 col-sm-4 col-4" style={{ backgroundColor: '#F3F3F4', position: 'relative' }}>
          <div className="store_timings" style={{ textAlign: 'center', width: '100%', fontWeight: '600', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{this.props.location.store_times ? this.props.location.store_times.map((st) => <div key={st}>{st}</div>) : null}</div>
        </div>
      </div>
    );
  }
});

const StoreTable = createReactClass({
  render: function () {
    var rows = [];
    var storeIds = [];
    var zipcodeDistances = [];
    var lastState = null;

    this.props.locations.forEach(function (location) {
      var lookupZip = zipcodes.lookup(this.props.filterText);
      if (lookupZip) {
        var lookupZipGetState = this.props.statesAbbr.filter((state) => {
          return state.abbr == lookupZip.state;
        });
        var lookupZipState = "N/A";
        if (lookupZipGetState.length > 0) {
          lookupZipState = lookupZipGetState[0].value;
        }

        if (`${location.city.toLowerCase()}`.indexOf(lookupZip.city.toLowerCase()) === -1) {
           if (`${location.state.toLowerCase()}`.indexOf(lookupZipState.toLowerCase()) === -1) {
             return;
           }
        }

        let addToIndex = zipcodeDistances.findIndex((zip) => zipcodes.distance(lookupZip.zip, location.zipcode) < zip);
        if (addToIndex == -1) {
          zipcodeDistances.push(zipcodes.distance(lookupZip.zip, location.zipcode));
          rows.push(<StoreDetailRow location={location} key={location.store_id} />);
          lastState = location.state;

        } else {
          zipcodeDistances.splice(addToIndex, 0, zipcodes.distance(lookupZip.zip, location.zipcode));

          rows.splice(addToIndex, 0, (<StoreDetailRow location={location} key={location.store_id} />));
          lastState = location.state;
        }

        storeIds.push(location.store_id);

        return;

      } else {
        if (`${location.city.toLowerCase()}, ${location.state.toLowerCase()} ${location.zipcode}`.indexOf(this.props.filterText.toLowerCase()) === -1) {
          return;
        }
      }

      if (location.state !== lastState) {
        rows.push(<StateRow state={location.state} key={location.state} />);
      }
      rows.push(<StoreDetailRow location={location} key={location.store_id} />);
      lastState = location.state;

      storeIds.push(location.store_id);

    }.bind(this));

    if (rows.length === 0) {
      rows.push(<h3 style={{ textAlign: 'center' }}>No Results</h3>);
    } else {
      if (zipcodeDistances.length > 0) {
        rows.splice(0, 0, (<StateRow state={lastState} key={lastState} />));
      }
    }

    all_markers.forEach((marker) => {
      if (!storeIds.includes(marker.store_id)) {
        marker.marker.setMap(null);

      } else {
        if (!marker.marker.getMap()) {
          marker.marker.setMap(map);
        }
      }
    })

    return (
      <div>
        {rows}
      </div>
    );
  }
});

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
  }

  handleFilterTextChange(e) {
    this.props.onFilterTextChange(e.target.value);
  }

  render() {
    return (
      <form onSubmit={e => { e.preventDefault(); }}>
        <input
          type="text"
          className="search-input-bar"
          placeholder="&#xf002;    FIND A STORE (CITY, STATE AND / OR ZIP CODE)"
          value={this.props.filterText}
          onChange={this.handleFilterTextChange}
          style={{ fontFamily: "'Font Awesome 5 Free'", fontWeight: '600'}}
        />
      </form>
    );
  }
}

class FilterableStoreTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
  }

  handleFilterTextChange(filterText) {
    $("#filterable-locations").animate({ scrollTop: 0 }, "fast");

    this.setState({
      filterText: filterText
    });
  }

  render() {
    return (
      <div>
        {/* Filter Bar */}
        <section className="filterbar text-white">
          <div className="col-md-12 col-sm-12 col-12 sort-search">
            <SearchBar
              filterText={this.state.filterText}
              onFilterTextChange={this.handleFilterTextChange}
            />

          </div>
        </section>
        {/* End Filter Bar */}

        <div className="container" style={{ maxWidth: '100%', margin: 0 }}>
          <section>
            <Row>
              <Col id="filterable-locations" lg={6} md={6} style={{ padding: 30, height: 500, overflow: 'scroll' }}>
                <div>
                  <StoreTable
                    statesAbbr={this.props.statesAbbr}
                    locations={this.props.locations}
                    filterText={this.state.filterText}
                  />
                </div>
              </Col>
              <Col lg={6} md={6} style={{ maxWidth: '100%', padding: 0 }}>
                <div id="map"></div>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    );
  }
}

class LocationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swadlocations: '',
    };
  }

  componentDidMount() {
    axios.get('/api/stores?state=all')
      .then((results) => {
        var locations = results.data;
        var swadlocations = locations.sort(function (a, b) {
          if (a.state.toUpperCase() < b.state.toUpperCase()) return -1;
          if (a.state.toUpperCase() > b.state.toUpperCase()) return 1;
          return 0;
        });
        this.setState({
          swadlocations: swadlocations,
        });

        map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: 37.3716, lng: -92.4785 },
          zoom: 4
        });
        $.each(locations, function(index, element) {
          var infowindow = new window.google.maps.InfoWindow({
            content: `<h4>${element.name}</h4><p>${element.street}<br/>${element.city}, ${element.state} ${element.zipcode}<br/>${element.phone_number}</p><a href="https://www.google.com/maps/?q=${element.name.replace(/ /g,'+')}+${element.street.replace(/ /g,'+')}+${element.city.replace(/ /g,'+')}+${element.state.replace(/ /g,'+')}+${element.zipcode.replace(/ /g,'+')}" target="_blank">Get Directions</a>`
          });

          var marker = new window.google.maps.Marker({
            position: {lat: parseFloat(element.lat), lng: parseFloat(element.long)},
            map: map,
            markerID: `${element.store_id}`
          });

          marker.addListener('click', function() {
            $('#filterable-locations').animate({
              scrollTop: $('#filterable-locations').scrollTop() + $(`#${element.state.replace(/ /g,"_")}`).position().top
            }, 1000);
            if (activeInfoWindow) { activeInfoWindow.close(); }
            infowindow.open(map, marker);
            activeInfoWindow = infowindow;
          });

          all_markers.push({store_id: element.store_id, marker: marker});
        });
      });
  }

  render() {
    if (this.state.swadlocations === "") {
      return (<Loading />);
    }

    return (
      <div>
        <Header />
        <div id="locations-page">

          {/* Location */}
          <header className="masthead text-center text-white d-flex" style={{ backgroundImage: `url(${eattogether})`, height: '80vh', paddingTop: 0 }}>
            <div id="locations" className="full-image-block container">
              <div className="row">
                <div className="col-xl-7 col-lg-6"></div>
                <div id="locations-details" className="postbox location-page-postbox col-xl-4 col-lg-5 col-sm-12 col-12">
                  <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                    <h2>
                      <center><p className="top-subtitle">find us</p></center>
                      <strong>SWAD LOCATIONS</strong>
                    </h2>
                    <p className="postinfo mb-3 mt-3">
                      SWAD products appeal to all individuals across the world.
                      You can enjoy our products with your friends and family for any occassion.
                      Find SWAD products by entering your search below.
                      </p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </header>
          {/* End Location */}

          <FilterableStoreTable statesAbbr={statesAbbr} locations={this.state.swadlocations} />

        </div>
        <Footer />
      </div>
    );
  }
}

export default LocationsPage;
