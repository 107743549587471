import React from 'react';
import { Row, Col, DropdownButton,  MenuItem } from 'react-bootstrap';
import avatar from '../../images/avatar.png'

const Dashboard = ({
  show,
  viewBy,
  filterViewAll,
  searchbox,
  filterFeatured,
  sortBy,
  sortRowsBy,
  searchPlaceholder,
  filterItems,
  showStores,
  showProducts,
  showRecipes,
  locations,
  products,
  recipes,
  updateStateObj,
  handleCheck,
  handleDelete,
  nextPage,
  productOffset
 }) => {

  const locationList = locations.map((location) =>
    <div className="row item-row" key={location.store_id}>
      <div className="col-sm-3">{location.store_id}</div>
      <div className="col-sm-3">{location.name}</div>
      <div className="col-sm-5">{location.street} {location.city}, {location.state} {location.zipcode}</div>
      <div className="col-sm-1" style={{display:'flex'}}>
        <div className="svg-fa pointer" onClick={() => updateStateObj(location, 'store')}><svg aria-hidden="true" data-prefix="fas" data-icon="pencil-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg></div>
        <div className="svg-fa pointer" onClick={() => handleDelete(location, 'store')}><svg aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z"></path></svg></div>
      </div>
    </div>
  )

  const productsList = products.sort((a, b) => {
    if (a.productofthemonth === null) {
      return 1;
    }
    if (b.productofthemonth === null) {
      return -1;
    }

    if (a.productofthemonth.year === b.productofthemonth.year) {
      if (a.productofthemonth.month < b.productofthemonth.month) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return -1;
    }

  }).map((product) =>
    <div className="row item-row" key={product.barcode}>
      {product.productofthemonth ?
        (<div className="col-sm-1">
          <a style={{cursor: 'pointer'}} onClick={() => updateStateObj(product, 'product')}>{product.productofthemonth.month}/{product.productofthemonth.year}</a>
        </div>)
         :
         (<div className="col-sm-1">
           <input type="checkbox" defaultChecked={false} onChange={(e) => handleCheck(e, product, 'potm')}/>
         </div>)
      }
      <div className="col-sm-2">{product.barcode}</div>
      <div className="col-sm-2">{product.sku}</div>
      <div className="col-sm-2">{product.name}</div>
      <div className="col-sm-2">{product.product_sizes}</div>
      <div className="col-sm-2">{product.category}</div>
      <div className="col-sm-1" style={{display:'flex'}}>
        <div onClick={() => updateStateObj(product, 'product')} className="pointer svg-fa"><svg aria-hidden="true" data-prefix="fas" data-icon="pencil-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg></div>
        <div className="svg-fa pointer" onClick={() => handleDelete(product, 'product')}><svg aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z"></path></svg></div>
      </div>
    </div>
  )

  const recipesList = recipes.sort((a, b) => {
    if (a.recipeoftheweek === null) {
      return 1;
    }
    if (b.recipeoftheweek === null) {
      return -1;
    }

    if (a.recipeoftheweek.year === b.recipeoftheweek.year) {
      if (a.recipeoftheweek.weeknum < b.recipeoftheweek.weeknum) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return -1;
    }

  }).map((recipe) =>
    <div className="row item-row" key={recipe.recipe_id}>
      {recipe.recipeoftheweek ?
        (<div className="col-sm-1">
          <a style={{cursor: 'pointer'}} onClick={() => updateStateObj(recipe, 'recipe')}>{recipe.recipeoftheweek.weeknum} - {recipe.recipeoftheweek.year}</a>
        </div>)
         :
         (<div className="col-sm-1">
           <input type="checkbox" defaultChecked={recipe.recipeoftheweek ? true : false} onChange={(e) => handleCheck(e, recipe, 'rotw')}/>
         </div>)
      }
      <div className="col-sm-2">{recipe.name}</div>
      <div className="col-sm-2"></div>
      <div className="col-sm-2">{recipe.category}</div>
      <div className="col-sm-2">{recipe.amount_serves}</div>
      <div className="col-sm-2">{recipe.description}</div>
      <div className="col-sm-1" style={{display:'flex'}}>
        <div onClick={() => updateStateObj(recipe, 'recipe')} className="pointer svg-fa"><svg aria-hidden="true" data-prefix="fas" data-icon="pencil-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg></div>
        <div className="svg-fa pointer" onClick={() => handleDelete(recipe, 'recipe')}><svg aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z"></path></svg></div>
      </div>
    </div>
  )

  return (
    <div className="col-sm-10" id="admin-right" style={{height:'100vh'}}>
      <div className="row" style={{padding:'2%'}}>
        <div className="col-sm-9" style={{display:'flex'}}>
          <img src={avatar} alt ="" id="admin-pic" />
          <div id="james">Hello, ADMIN</div>
        </div>
      </div>
      <h1 id="admin-dash-title">DASHBOARD</h1>
      <div id="two-pcnt">
        <div className="row">
          <div className="col-sm-2 pointer" onClick={() => show('stores')}><span id="stores-tab" className="dash-tab" style={{fontWeight: 'bolder', textDecoration: 'underline'}}>STORES</span></div>
          <div className="col-sm-2 pointer" onClick={() => show('products')}><span id="products-tab" className="dash-tab">PRODUCTS</span></div>
          <div className="col-sm-2 pointer" onClick={() => show('recipes')}><span id="recipes-tab" className="dash-tab">RECIPES</span></div>
        </div>
        <br />
        <Row>
          <Col sm={6} className="no-gutters">
            <Row>
              <Col sm={6}>
                <DropdownButton
                  title={viewBy}
                >
                  <MenuItem onClick={() => filterViewAll(searchbox)}>All</MenuItem>
                  {showStores === 'none' ? <MenuItem onClick={() => filterFeatured('Featured')}>Featured</MenuItem> : null }
                </DropdownButton>
              </Col>

              <Col sm={6}>
                <DropdownButton
                  title={sortBy}
                >
                  <MenuItem onClick={() => sortRowsBy('Default')}>Default</MenuItem>
                  {showStores === 'none' ? <MenuItem onClick={() => sortRowsBy('Featured')}>Featured</MenuItem> : null }
                  {showStores === 'none' ? <MenuItem onClick={() => sortRowsBy('Category')}>Category</MenuItem> : null }
                  {showStores === 'block' ? <MenuItem onClick={() => sortRowsBy('Name')}>Name</MenuItem> : null }
                  {showStores === 'block' ? <MenuItem onClick={() => sortRowsBy('State')}>State</MenuItem> : null }
                  {showProducts === 'block' ? <MenuItem onClick={() => sortRowsBy('SKU')}>SKU</MenuItem> : null }
                  {showProducts === 'block' ? <MenuItem onClick={() => sortRowsBy('Sizes')}>Sizes</MenuItem> : null }
                </DropdownButton>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                <div id="keyword-form">
                  <input type="text" placeholder={searchPlaceholder} name="filter" onKeyUp={filterItems} ref={input => searchbox = input} />
                  <i className="fa" id="fa-search">&#xf002;</i>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="fadeIn pad-15" style={{display:showStores}}>
          <div className="row title-bar">
            <div className="col-sm-3">STORE NUMBER</div>
            <div className="col-sm-3">STORE NAME</div>
            <div className="col-sm-5">ADDRESS</div>
            <div className="col-sm-1"></div>
          </div>
          {locationList}
        </div>
        <div className="fadeIn pad-15" style={{display:showProducts}}>
          <div className="row title-bar">
            <div className="col-sm-1">P.O.T.M.</div>
            <div className="col-sm-2">BAR CODE</div>
            <div className="col-sm-2">SKU</div>
            <div className="col-sm-2">NAME</div>
            <div className="col-sm-2">SIZES</div>
            <div className="col-sm-2">CATEGORY</div>
            <div className="col-sm-1"></div>
          </div>
          {productsList}
          {/*<div className="row">
            <a style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => nextPage('product')}>Next Page</a>
          </div>*/}
        </div>
        <div className="fadeIn pad-15" style={{display:showRecipes}}>
          <div className="row title-bar">
            <div className="col-sm-1">R.O.T.W.</div>
            <div className="col-sm-2">RECIPE NAME</div>
            <div className="col-sm-2">INGREDIENTS</div>
            <div className="col-sm-2">CATEGORY</div>
            <div className="col-sm-2">AMOUNT SERVES</div>
            <div className="col-sm-2">DESCRIPTION</div>
            <div className="col-sm-1"></div>
          </div>
          {recipesList}
        </div>
    </div>
  </div>
  )
}

export default Dashboard;
