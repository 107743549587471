import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const ProductRecipeModal = props => (
  <div className="modal fade" id="associated-recipe-modal" tabIndex="-1" role="dialog" aria-labelledby="associated-recipe-modal-label" aria-hidden="true">
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <a className="close-modal" href="_#" data-dismiss="modal" aria-label="Close">x</a>
        <div className="modal-header">
          <h5 className="modal-title text-center" id="product-modal-label">Recipe Details</h5>
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="modal-product-images col-lg-6">
              <div className="modal-current-image">
              {
                props.recipe.image_path ? <img style={{objectFit: 'cover'}} src={`${props.recipe.image_path}`} alt="" /> : null
              }
              </div>
              <div className="modal-slides-image" style={{display:'none'}}>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 center">
                    <ul>
                      <li>
                        <a className="show-modal-thumbnail" href="_#"><div className="modal-img-thumbnail"></div></a>
                      </li>
                      <li>
                        <a className="show-modal-thumbnail" href="_#"><div className="modal-img-thumbnail"></div></a>
                      </li>
                      <li>
                        <a className="show-modal-thumbnail" href="_#"><div className="modal-img-thumbnail"></div></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product-desc-title">
                <div className="row">
                  <div className="col-lg-12">

                    {
                      props.recipe.name ? <h5 className="product-modal-title">{props.recipe.name} <p className="product-tag">{props.recipe.featured}</p> </h5> : null
                    }


                  </div>
                </div>
              </div>
              <div className="product-desc semibold">
                {
                  props.recipe.description ? <p>{props.recipe.description}<br/></p> : null
                }
              </div>
              <div className="product-desc semibold mt-2">
                {
                  props.recipe.duration ? <p className="mb-0">{props.recipe.duration}</p> : null
                }
                {
                  props.recipe.amount_serves ? <p className="mb-0">{props.recipe.amount_serves}</p> : null
                }
                {
                  props.recipe.difficulty ? <p className="mb-0">{props.recipe.difficulty}</p> : null
                }
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row mt-5">
            <p><b>Ingredients</b></p>
          </div>
          <div className="row">
            <div className="col-md-12">
            {
              props.recipe.products ? props.recipe.products.map((product, index) => { return (<a className="mb-0" onClick={() => props.setProductModal(product.sku)} href="_#" data-toggle="modal" data-target="#product-modal" data-dismiss="modal" style={{fontSize: 20}}>- {product.name}<br/></a>) }) : null
            }

            {
              props.recipe.ingredients ? props.recipe.ingredients.map((ingredient, index) => { return (<p className="mb-0" style={{fontSize: 20}}>- {ReactHtmlParser(ingredient)}<br/></p>) }) : null
            }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
            {
              props.recipe.steps_mobile ? props.recipe.steps_mobile.map((step, index) => { return (<p className="mb-0 mt-2" style={{fontSize: 20}}>{index+1}.  {ReactHtmlParser(step)}<br/><br/></p>) }) : null
            }
            </div>
          </div>

          <div className="row modal-recipes">
            <div className="associated-recipes col-lg-12 semibold">
            {
              props.recipe.products ?
              (<p>Products in this Recipe</p>) : null
            }

              <div className="modal-recipes-list text-center">
              {
                props.recipe.products ? <div className="row">{
                  props.recipe.products.map((product, index) => {
                    return <div className="col-lg-3 col-sm-3"><a onClick={() => props.setProductModal(product.sku)} href="_#" data-toggle="modal" data-target="#product-modal" data-dismiss="modal" style={{fontSize: 20}}><div className="modal-recipe-item" style={{backgroundImage: "url(" + product.image_path + ")"}}></div><p>{product.name}</p></a></div>; })
                }</div> : null
              }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default ProductRecipeModal;
