import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class ProductPreview extends React.Component {
  render() {
    let image_path = this.props.image_path ? this.props.image_path : "https://swad.nyc3.digitaloceanspaces.com/products/front/swadcomingsoon.png";
    return (
      <div className={`${this.props.productSize} col-lg-${this.props.colSize} col-sm-${this.props.colSize}`}>
        <a onClick={() => this.props.setProductModal(this.props.sku)} href="_#" data-toggle="modal" data-target="#product-modal" style={{width: "100%"}}>
          {this.props.showFeatured ? <ScrollAnimation animateOnce={true} animateIn="fadeIn" className="featured"><p>Featured{/*this.props.feature_tag*/}</p></ScrollAnimation> : null}
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" className="product" style={{backgroundImage: "url(" + image_path + ")"}}></ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" className="product-details"><div onClick={() => this.props.setProductModal(this.props.sku)} className="secondary-a-dark" href="_#" data-toggle="modal" data-target="#product-modal">{this.props.name}</div></ScrollAnimation>
        </a>
      </div>
    );
  }
}
