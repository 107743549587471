import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import Header from '../components/generic/Header';
import Loading from '../components/generic/Loading';
import RecipeOfWeek from '../components/recipes/RecipeOfWeek';
import ProductPreview from '../components/products/ProductPreview';
import FeaturedProducts from '../components/home/FeaturedProducts';
import ProductOfMonth from '../components/products/ProductOfMonth';
import ShareRecipes from '../components/home/ShareRecipes';
import EatingTogether from '../components/home/EatingTogether';
import OurSpices from '../components/home/OurSpices';
import LocationsHero from '../components/home/LocationsHero';
import MiniInstagram from '../components/home/MiniInstagram';
import MiniGallery from '../components/home/MiniGallery';
import RecipeModal from '../components/modals/RecipeModal';
import RecipeProductModal from '../components/modals/RecipeProductModal';
import ProductModal from '../components/modals/ProductModal';
import ProductRecipeModal from '../components/modals/ProductRecipeModal';
import Footer from '../components/generic/Footer';
import AdminLogin from '../../Admin/containers/AdminLogin';

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swadproductcategories: "",
      swadproducts: "",
      swadrecipecategories: "",
      swadrecipes: "",
      swadallrecipes: "",
      swadproductofmonth: "",
      productsleft: "",
      productsright: "",
      productsslide1: "",
      productsslide2: "",
      viewproduct: "",
      viewrecipe: ""
    };

    this.setProductModal = this.setProductModal.bind(this);
    this.setRecipeModal = this.setRecipeModal.bind(this);
    this.changeModalImage = this.changeModalImage.bind(this);

    document.addEventListener('contextmenu', this.handleContextMenu);
  }

  componentDidMount() {
    axios.get('/api/all_products')
      .then((results) => {
	const getProducts = results.data;
        const products = getProducts.filter((p) => {
          if (p.category != null) {
            if (p.category.toLowerCase().includes("organic")) {
              return false
            }
          }
	  if (p.name.toLowerCase().includes("organic")) {
              return false
          }

          return true
        });

        const today = new Date();

        var usePOTM;
        var thisYearsPOTM = products.filter((product) => product.productofthemonth !== null && String(product.productofthemonth.year) === String(today.getFullYear()));
        if (thisYearsPOTM.length > 0) {
          const checkMonthIsThere = thisYearsPOTM.filter((product) => String(product.productofthemonth.month) == String(today.getMonth()+1));
          if (checkMonthIsThere.length > 0) {
            usePOTM = checkMonthIsThere.map((p) => {
              return <ProductOfMonth hasBackground={true} description={p.productofthemonth.description} bg_image_path={p.productofthemonth.image_path ? p.productofthemonth.image_path : p.image_path} setProductModal={this.setProductModal} key={p.productofthemonth.month} {...p} />;
            })[0];

          } else {
            const checkPrevMonthsThisYear = thisYearsPOTM.filter((product) => product.productofthemonth.month > (today.getMonth()+1)).sort((a, b) => b.productofthemonth.month - a.productofthemonth.month);
            if (checkPrevMonthsThisYear.length > 0) {
              usePOTM = checkPrevMonthsThisYear.map((p) => {
                return <ProductOfMonth hasBackground={true} description={p.productofthemonth.description} bg_image_path={p.productofthemonth.image_path ? p.productofthemonth.image_path : p.image_path} setProductModal={this.setProductModal} key={p.productofthemonth.month} {...p} />;
              })[0];
            }
          }
        } else {
          usePOTM = products.filter((product) => product.productofthemonth !== null && product.productofthemonth.month >= (today.getMonth()+1)).sort((a, b) => b.productofthemonth.month - a.productofthemonth.month).map((p) => {
            return <ProductOfMonth hasBackground={true} description={p.productofthemonth.description} bg_image_path={p.productofthemonth.image_path ? p.productofthemonth.image_path : p.image_path} setProductModal={this.setProductModal} key={p.productofthemonth.month} {...p} />;
          })[0];
        }

        if (!usePOTM) {
          usePOTM = (<ProductOfMonth key={0} hasBackground={true} bg_image_path={products[0].image_path} description={"Check out one of our favorite products!"} setProductModal={this.setProductModal} {...products[0]} />);
        }

        var filterCategories = []; //to filter by category name
        var categories = [];

        const featured = products.filter((product) => product.barcode == usePOTM.props.barcode).map((product) => {
          if (product.category != null) {
            if (!filterCategories.includes(product.category)) {
              filterCategories.push(product.category);
              categories.push(<Link className="dropdown-item" to={`/products/${encodeURIComponent(product.category.toLowerCase())}`}>{product.category}</Link>);
            }
          }

          return <ProductPreview key={product.sku} showFeatured={true} setProductModal={this.setProductModal} productSize={'product-lg'} colSize={12} {...product} />;
        })[0];

        const regular = products.filter((product) => product.barcode != usePOTM.props.barcode).map((product) => {
          if (product.category != null) {
            if (!filterCategories.includes(product.category)) {
              filterCategories.push(product.category);
              categories.push(<Link className="dropdown-item" to={`/products/${encodeURIComponent(product.category.toLowerCase())}`}>{product.category}</Link>);
            }
          }

          return <ProductPreview key={product.sku} showFeatured={false} setProductModal={this.setProductModal} productSize={'product-sm'} colSize={6} {...product} />;
        });

        const regular_no_empty_images = products.filter((product) => product.barcode != usePOTM.props.barcode && product.image_path !== null).slice(0, 4).map((product) => {
          return <ProductPreview key={product.sku} showFeatured={false} setProductModal={this.setProductModal} productSize={'product-sm'} colSize={6} {...product} />;
        });

        this.setState({ productsleft: featured, productsright: regular_no_empty_images, productsslide1: regular.slice(4, 8), productsslide2: regular.slice(8, 12) });
        this.setState({ swadproductofmonth: usePOTM });
        this.setState({ swadproducts: products, swadproductcategories: categories });
      });

    axios.get('/api/all_recipes')
      .then((results) => {
        const recipes = results.data;

        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
        const thisWeekNum =  Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);

        var thisYearsRecipesOfTheWeek = recipes.filter((recipe) => recipe.recipeoftheweek !== null && String(recipe.recipeoftheweek.year) === String(today.getFullYear()));

        var useRecipeOfTheWeek;
        if (thisYearsRecipesOfTheWeek.length > 0) {
          const checkWeekNumIsThere = thisYearsRecipesOfTheWeek.filter((recipe) => String(recipe.recipeoftheweek.weeknum) == String(thisWeekNum));
          if (checkWeekNumIsThere.length > 0) {
            useRecipeOfTheWeek = checkWeekNumIsThere.map((r) => {
              return <RecipeOfWeek key={r.recipeoftheweek.weeknum} rotw_desc={r.recipeoftheweek.description} bg_image_path={r.recipeoftheweek.image_path ? r.recipeoftheweek.image_path : r.image_path} setRecipeModal={this.setRecipeModal} home={true} {...r} />;
            })[0];

          } else {
            const checkPrevWeeksThisYear = thisYearsRecipesOfTheWeek.filter((recipe) => recipe.recipeoftheweek.weeknum > thisWeekNum).sort((a, b) => b.recipeoftheweek.weeknum - a.recipeoftheweek.weeknum);
            if (checkPrevWeeksThisYear.length > 0) {
              useRecipeOfTheWeek = checkPrevWeeksThisYear.map((r) => {
                return <RecipeOfWeek key={r.recipeoftheweek.weeknum} rotw_desc={r.recipeoftheweek.description} bg_image_path={r.recipeoftheweek.image_path ? r.recipeoftheweek.image_path : r.image_path} setRecipeModal={this.setRecipeModal} home={true} {...r} />;
              })[0];
            }
          }
        } else {
          useRecipeOfTheWeek = recipes.filter((recipe) => recipe.recipeoftheweek !== null && recipe.recipeoftheweek.weeknum >= thisWeekNum).sort((a, b) => b.recipeoftheweek.weeknum - a.recipeoftheweek.weeknum).map((r) => {
            return <RecipeOfWeek key={r.recipeoftheweek.weeknum} rotw_desc={r.recipeoftheweek.description} bg_image_path={r.recipeoftheweek.image_path ? r.recipeoftheweek.image_path : r.image_path} setRecipeModal={this.setRecipeModal} home={true} {...r} />;
          })[0];
        }

        if (!useRecipeOfTheWeek) {
          useRecipeOfTheWeek = (<RecipeOfWeek key={0} bg_image_path={recipes[0].image_path} rotw_desc={"Check out one of our favorite recipes!"} setRecipeModal={this.setRecipeModal} home={true} {...recipes[0]} />);
        }

        var filterRecipeCategories = []; //to filter by category name
        var recipeCategories = [];
        recipes.forEach((recipe) => {
          if (recipe.category != null) {
            if (!filterRecipeCategories.includes(recipe.category)) {
              filterRecipeCategories.push(recipe.category);
              recipeCategories.push(<Link className="dropdown-item" to={`/recipes/${encodeURIComponent(recipe.category.toLowerCase())}`}>{recipe.category}</Link>);
            }
          }
        });

        this.setState({ swadallrecipes: recipes, swadrecipecategories: recipeCategories, swadrecipes: useRecipeOfTheWeek })
      })

  }

  handleContextMenu(event) {
    event.preventDefault();
  }

  setProductModal(sku) {
    this.setState({ viewrecipe: "" });
    this.state.swadproducts.forEach((product) => {
      if (String(sku) === String(product.sku)) {
        this.setState({ viewproduct: product });
      }
    });
  }

  setRecipeModal(id) {
    this.setState({ viewproduct: "" });
    this.state.swadallrecipes.forEach((recipe) => {
      if (String(id) === String(recipe.recipe_id)) {
        this.setState({ viewrecipe: recipe });
      }
    });
  }

  changeModalImage(image) {
    $('#main-product-image').attr('src', image);
  }

  render() {
    if (this.state.swadproducts === "" || this.state.swadproductofmonth === "" || this.state.swadrecipes === "") {
      return (<Loading />);
    }

    return (
      <div>
        <Header />
        <div className="home">

          {/* Recipes of the Week */}
          <header className="masthead text-center text-white d-flex" style={{ backgroundImage: "url(" + this.state.swadrecipes.props.bg_image_path + ")" }}>
            {this.state.swadrecipes}
          </header>
          {/* End Recipes of the Week */}

          <FeaturedProducts featuredproduct={this.state.productsleft} fourproducts={this.state.productsright} categories={this.state.swadproductcategories}/>

          {/* Product of the Month */}
          {this.state.swadproductofmonth}
          {/* End Product of the Month */}

          <ShareRecipes categories={this.state.swadrecipecategories} />

          <EatingTogether />

          <OurSpices />

          <LocationsHero />

          <MiniInstagram />

          <MiniGallery />

          <RecipeModal setProductModal={this.setProductModal} recipe={this.state.viewrecipe} />
          <RecipeProductModal product={this.state.viewproduct} setRecipeModal={this.setRecipeModal} />
          <ProductModal changeModalImage={this.changeModalImage} setRecipeModal={this.setRecipeModal} product={this.state.viewproduct} />
          <ProductRecipeModal recipe={this.state.viewrecipe} setProductModal={this.setProductModal} />
          <AdminLogin />

        </div>
        <Footer />
      </div>
    );
  }
}

export default IndexPage;
